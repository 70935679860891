<div class="row entry-row submenu-row">
  <div class="col-auto">
    <h1 class="content-header-title">
      {{ 'Notification::Notifications' | abpLocalization }}
    </h1>
  </div>
  <div class="col-md-auto pl-md-0">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a href="javascript:void(0)" (click)="onClickBack($event)">
          <i class="fas fa-arrow-circle-left"></i>
          {{ 'Notification::Back' | abpLocalization }}
        </a>
      </li>
      <li class="breadcrumb-item full-opacity">
        <button class="ca-btn ca-btn-link ca-btn-link-sm" (click)="onClickFilter($event, 'recent')">
          {{
            'Notification::RecentCount'
              | abpLocalization: (notificationCountSummary$ | async).recent.toString()
          }}
        </button>
      </li>
      <li class="breadcrumb-item full-opacity">
        <button class="ca-btn ca-btn-link ca-btn-link-sm" (click)="onClickFilter($event, 'unread')">
          {{
            'Notification::UnreadCount'
              | abpLocalization: (notificationCountSummary$ | async).unread.toString()
          }}
        </button>
      </li>
      <li class="breadcrumb-item full-opacity">
        <button class="ca-btn ca-btn-link ca-btn-link-sm" (click)="onClickFilter($event, 'all')">
          {{
            'Notification::TotalCount'
              | abpLocalization: (notificationCountSummary$ | async).total.toString()
          }}
        </button>
      </li>
    </ol>
  </div>
  <div class="col">
    <div class="text-lg-right pt-2 ca-toolbar" id="AbpContentToolbar">
      <div class="ca-toolbar-west-section">
        <div class="toolbar-item first">
          <button
            class="btn ca-btn ca-btn-neutral-toolbar toolbar-button"
            [disabled]="(notificationCountSummary$ | async).unread <= 0"
            type="button"
            (click)="onMarkAllAsClick($event)"
          >
            <i class="far fa-envelope-open"></i
            ><span>{{ 'Notification::MarkAllAsRead' | abpLocalization }}</span>
          </button>
        </div>
        <div class="toolbar-item pl-3 text-center">
          <!-- <mat-divider [vertical]="true" class="menu-divider"></mat-divider> -->
        </div>
        <div class="toolbar-item">
          <!-- <button
              class="btn ca-btn ca-btn-neutral-toolbar toolbar-button"
              type="button"
              *ngIf="canCreateQuery"
              (click)="addNew($event)"
            >
              <i class="far fa-plus-circle"></i><span>{{ '::AddNew' | abpLocalization }}</span>
            </button> -->
        </div>
      </div>
      <div class="ca-toolbar-east-section">
        <div class="toolbar-item last">
          <!-- <mat-slide-toggle
              labelPosition="before"
              (change)="onIncludePassivesChange($event)"
              formControlName="includePassives"
            >
              {{ 'Query::IncludePassiveQueries' | abpLocalization }}
            </mat-slide-toggle> -->
        </div>
      </div>
    </div>
  </div>
</div>
