<form [formGroup]="form" (ngSubmit)="onSubmitForm($event)">
  <div class="form-group pt-2" formGroupName="sourceName">
    <label for="sourceName">{{ 'Settings::ZendeskIntegrationSourceName' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="sourceName"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="tenantName">
    <label for="tenantName">{{ 'Settings::ZendeskIntegrationTenantName' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="tenantName"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="userName">
    <label for="userName">{{ 'Settings::ZendeskIntegrationUserName' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="userName"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="password">
    <label for="password">{{ 'Settings::ZendeskIntegrationPassword' | abpLocalization }}</label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="password"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="migrationStartDate">
    <label for="migrationStartDate">{{ 'Settings::ZendeskIntegrationMigrationStartDate' | abpLocalization }}</label>
    <input
      type="text"
      placeholder="2023-01-31"
      class="form-control form-control-sm"
      id="migrationStartDate"
      formControlName="value" />
  </div>
  <button type="button" type="submit" class="btn btn-primary mt-3 mb-3" [disabled]="!form.valid">
    <i class="me-1 fas fa-save"></i>{{ 'AbpUi::Save' | abpLocalization }}
  </button>
</form>
