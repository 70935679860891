export enum Editor {
  Date,
  GenericLookup,
  Text,
  Query,
  Assignment,
  AutomaticAssignment,
  Form,
  Category,
  Department,
  User,
  Role,
  ConversationSide,
  Topic,
  Tag,
  AttachedData,
  Number,
  QuickSearchWithConversationSide,
  BooleanSelection,
  NumberWithOperator,
  Scenario,
  FormVersion,
  Question,
  Period,
  Section,
  LastLoginDate
}
