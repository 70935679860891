<ng-template #itemTemplate let-item="item">
  <div class="tag-selection-panel-tag-name">
    <div class="line-ellipsis">{{ item.name }}</div>
  </div>
</ng-template>

<ng-template #selectionTemplate let-item="item">
  <div class="filter-name">{{ item.name }}</div>
</ng-template>

<ng-container [formGroup]="editorForm">
  <div class="row display-inline row-height">
    <div class="col-5" *ngIf="hasLabel">
      <label [for]="'text-editor-' + this.actualConfig.key" class="label-padding"
        >{{ actualConfig.label | abpLocalization }}
      </label>
    </div>
    <div [class]="attachedDataSelectionClass + ' overflow-hidden pb-2'">
      <div class="row display-inline">
        <div class="col overflow-hidden">
          <ca-select
            formControlName="editor"
            (change)="attachedDataSelectionChanged($event)"
            [(ngModel)]="selectedAttachedDataId"
            ><ca-option [value]="'-1'">
              {{ '::SelectAttachedData' | abpLocalization }}
            </ca-option>
            <ca-option *ngFor="let attachedData of attachedDataList" [value]="attachedData.id">
              {{ attachedData.name }}
            </ca-option></ca-select
          >
        </div>
        <div class="col overflow-hidden" *ngIf="selectedAttachedDataId > 0">
          <input
            *ngIf="!isSelectedAttachedDataMultipleChoice"
            type="text"
            class="form-control form-control-sm"
            formControlName="attachedDataValue" />
          <ca-select
            *ngIf="isSelectedAttachedDataMultipleChoice"
            formControlName="attachedDataValue"
            ><ca-option
              *ngFor="let attachedDataLookup of selectedAttachedDataLookupList"
              [value]="attachedDataLookup.value">
              {{ attachedDataLookup.value }}
            </ca-option></ca-select
          >
        </div>
      </div>
    </div>
  </div>
</ng-container>
