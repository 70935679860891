import {
  Component,
  OnInit,
  Input,
  ViewContainerRef,
  ComponentFactoryResolver,
  Type,
} from '@angular/core';
import { QueryTreeNodeNumberComponent } from '../query-builder-nodes/query-tree-node-number/query-tree-node-number.component';
import { QueryTreeNodeOperatorComponent } from '../query-builder-nodes/query-tree-node-operator/query-tree-node-operator.component';
import { QueryTreeNodeStringComponent } from '../query-builder-nodes/query-tree-node-string/query-tree-node-string.component';
import { QueryEditorBase } from 'src/core/models/query/query-editor-base';
import { QueryTreeNodeSimpleTermComponent } from '../query-builder-nodes/query-tree-node-simple-term/query-tree-node-simple-term.component';
import { QueryTreeNodeNearComponent } from '../query-builder-nodes/query-tree-node-near/query-tree-node-near.component';
import { QueryTreeNodeOrderedGroupComponent } from '../query-builder-nodes/query-tree-node-ordered-group/query-tree-node-ordered-group.component';
import { QueryTreeNodeOrderedSimpleTermComponent } from '../query-builder-nodes/query-tree-node-ordered-simple-term/query-tree-node-ordered-simple-term.component';
import { HttpClient } from '@angular/common/http';
import { QueryFieldDataType } from 'src/core/models/query/query-field-data-type.enum';
import { IQueryBuilderCategoryItem } from 'src/core/models/query/query-builder-category-item.interface';
import { QueryTreeNodeModel } from 'src/core/models/query/query-tree-node.model';
import { QueryTreeNodeTimeComponent } from '../query-builder-nodes/query-tree-node-time/query-tree-node-time.component';
import { QueryTreeNodeConversationDirectionComponent } from '../query-builder-nodes/query-tree-node-conversation-direction/query-tree-node-conversation-direction.component';
import { QueryTreeNodeCustomerGenderComponent } from '../query-builder-nodes/query-tree-node-customer-gender/query-tree-node-customer-gender.component';
import { ConversationFilterableFields } from 'src/core/models/conversation/conversation-filterable-fields.enum';
import { QueryTreeNodeQueryGroupComponent } from '../query-builder-nodes/query-tree-node-query-group/query-tree-node-query-group.component';
import { QueryTreeNodeSimpleTermWithRangeAndOccurenceComponent } from '../query-builder-nodes/query-tree-node-simple-term-with-range-and-occurence/query-tree-node-simple-term-with-range-and-occurence.component';
import { QueryTreeNodeUserSelectorComponent } from '../query-builder-nodes/query-tree-node-user-selector/query-tree-node-user-selector.component';
import { QueryTreeNodeDepartmentTreeComponent } from '../query-builder-nodes/query-tree-node-department-tree/query-tree-node-department-tree.component';
import { QueryTreeNodeMergeComponent } from '../query-builder-nodes/query-tree-node-merge/query-tree-node-merge.component';
import { QueryTreeNodeAttachedDataComponent } from '../query-builder-nodes/query-tree-node-attached-data/query-tree-node-attached-data.component';
import { QueryDetailBuilderComponent } from '../query-detail/query-detail-builder/query-detail-builder.component';
import { QueryTreeNodeNonFcrStatusComponent } from '../query-builder-nodes/query-tree-node-non-fcr-status/query-tree-node-non-fcr-status.component';
import { QueryTreeNodeTagComponent } from '../query-builder-nodes/query-tree-node-tag/query-tree-node-tag.component';
import { QueryTreeNodeCustomerSentimentComponent } from '../query-builder-nodes/query-tree-node-customer-sentiment/query-tree-node-customer-sentiment.component';
import { QueryTreeNodeAgreementStatusComponent } from '../query-builder-nodes/query-tree-node-agreement-status/query-tree-node-agreement-status.component';
import { QueryTreeNodeSentimentTrendComponent } from '../query-builder-nodes/query-tree-node-sentiment-trend/query-tree-node-sentiment-trend.component';
import { QueryTreeNodeConversationReleasingPartyComponent } from '../query-builder-nodes/query-tree-node-conversation-releasing-party/query-tree-node-conversation-releasing-party.component';
import { QueryTreeNodeAgentSentimentComponent } from '../query-builder-nodes/query-tree-node-agent-sentiment/query-tree-node-agent-sentiment.component';
import { QueryTreeNodeNegativeContentComponent } from '../query-builder-nodes/query-tree-node-negative-content/query-tree-node-negative-content.component';

@Component({
  selector: 'ca-query-tree-node-factory',
  templateUrl: './query-tree-node-factory.component.html',
  styleUrls: ['./query-tree-node-factory.component.scss'],
})
export class QueryTreeNodeFactoryComponent implements OnInit {
  @Input()
  node: QueryTreeNodeModel;

  @Input()
  queryBuilder: QueryDetailBuilderComponent;

  @Input()
  isReadonly: boolean;

  @Input()
  currentQueryId: number;

  private mappingsByDataType: any = {};
  private mappingsByField: any = {};
  private duplicationAllowedTypes = [
    this.queryFieldDataType.OrderedGroup,
    this.queryFieldDataType.OrderedSimpleTerm,
    this.queryFieldDataType.SimpleTerm,
    this.queryFieldDataType.NearQuery,
  ];

  private getEditor(categoryItem: IQueryBuilderCategoryItem): Type<QueryEditorBase> {
    let editor = null;
    const payload = categoryItem.payload as any;
    if (payload.field) {
      editor = this.mappingsByField[payload.field];
    }

    editor = editor ? editor : this.mappingsByDataType[categoryItem.dataType];

    return editor;
  }

  createComponent(): void {
    const editor = this.getEditor(this.node.categoryItem);
    if (editor) {
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory<QueryEditorBase>(editor);

      this.viewContainerRef.clear();
      const cmp = this.viewContainerRef.createComponent(componentFactory);
      const element: HTMLElement = cmp.location.nativeElement as HTMLElement;
      const allowDuplication = this.duplicationAllowedTypes.includes(
        this.node.categoryItem.dataType
      );

      const internalIdToDuplicate =
        this.node.categoryItem.dataType === this.queryFieldDataType.OrderedSimpleTerm
          ? this.node.categoryItem.payload.parentInternalId
          : this.node.categoryItem.payload.internalId;

      element.classList.add('full-width');
      element.classList.add('mt-1');
      element.classList.add('mb-1');
      element.classList.add('query-node');
      element.setAttribute(
        'data-internal-id',
        this.node.categoryItem.payload.internalId.toString()
      );
      element.setAttribute('data-internal-id-to-duplicate', internalIdToDuplicate.toString());
      element.setAttribute('data-allow-duplication', allowDuplication.toString());

      cmp.instance.node = this.node;
      cmp.instance.queryBuilder = this.queryBuilder;
      cmp.instance.isReadonly = this.isReadonly;
      cmp.instance.currentQueryId = this.currentQueryId;
    } else {
      console.log(this.node.categoryItem.title + '  not implemented in query builder.');
    }
  }

  constructor(
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private http: HttpClient,
    private queryField: ConversationFilterableFields,
    private queryFieldDataType: QueryFieldDataType
  ) {
    this.mappingsByDataType[this.queryFieldDataType.BigInteger] = QueryTreeNodeNumberComponent;
    this.mappingsByDataType[this.queryFieldDataType.Integer] = QueryTreeNodeNumberComponent;
    this.mappingsByDataType[this.queryFieldDataType.NearQuery] = QueryTreeNodeNearComponent;
    this.mappingsByDataType[this.queryFieldDataType.Operator] = QueryTreeNodeOperatorComponent;
    this.mappingsByDataType[this.queryFieldDataType.OrderedGroup] =
      QueryTreeNodeOrderedGroupComponent;
    this.mappingsByDataType[this.queryFieldDataType.OrderedGroupWithRange] =
      QueryTreeNodeOrderedGroupComponent;
    this.mappingsByDataType[this.queryFieldDataType.OrderedSimpleTerm] =
      QueryTreeNodeOrderedSimpleTermComponent;
    this.mappingsByDataType[this.queryFieldDataType.SimpleTerm] = QueryTreeNodeSimpleTermComponent;
    this.mappingsByDataType[this.queryFieldDataType.SimpleTermWithNot] =
      QueryTreeNodeSimpleTermComponent;
    this.mappingsByDataType[this.queryFieldDataType.SimpleTermWithOccurence] =
      QueryTreeNodeSimpleTermComponent;
    this.mappingsByDataType[this.queryFieldDataType.SimpleTermWithRange] =
      QueryTreeNodeSimpleTermComponent;
    this.mappingsByDataType[this.queryFieldDataType.SimpleTermWithRangeAndOccurence] =
      QueryTreeNodeSimpleTermWithRangeAndOccurenceComponent;
    this.mappingsByDataType[this.queryFieldDataType.String] = QueryTreeNodeStringComponent;
    this.mappingsByDataType[this.queryFieldDataType.QueryGroup] = QueryTreeNodeQueryGroupComponent;
    this.mappingsByDataType[this.queryFieldDataType.Time] = QueryTreeNodeTimeComponent;
    this.mappingsByDataType[this.queryFieldDataType.MergeQuery] = QueryTreeNodeMergeComponent;
    this.mappingsByField[queryField.Call_Direction] = QueryTreeNodeConversationDirectionComponent;
    this.mappingsByField[queryField.CallAnalysis_CustomerGender] =
      QueryTreeNodeCustomerGenderComponent;
    this.mappingsByField[queryField.Conversation_Agent] = QueryTreeNodeUserSelectorComponent;
    this.mappingsByField[queryField.Conversation_DepartmentId] =
      QueryTreeNodeDepartmentTreeComponent;
    this.mappingsByDataType[this.queryFieldDataType.AttachedData] =
      QueryTreeNodeAttachedDataComponent;
    this.mappingsByField[queryField.Call_NonFcrStatus] = QueryTreeNodeNonFcrStatusComponent;
    this.mappingsByDataType[this.queryFieldDataType.Tag] = QueryTreeNodeTagComponent;
    this.mappingsByField[queryField.Conversation_CustomerSentiment] =
      QueryTreeNodeCustomerSentimentComponent;
    this.mappingsByField[queryField.Conversation_SentimentTrend] =
      QueryTreeNodeSentimentTrendComponent;
    this.mappingsByField[queryField.Conversation_AgreementStatus] =
      QueryTreeNodeAgreementStatusComponent;
    this.mappingsByField[queryField.Call_ReleasingParty] =
      QueryTreeNodeConversationReleasingPartyComponent;
    this.mappingsByField[queryField.Conversation_AgentSentiment] =
      QueryTreeNodeAgentSentimentComponent;
    this.mappingsByField[queryField.Conversation_NegativeContent] =
      QueryTreeNodeNegativeContentComponent;
    this.mappingsByDataType[this.queryFieldDataType.NearQueryWithRange] =
      QueryTreeNodeNearComponent;
  }

  ngOnInit() {
    this.createComponent();
  }
}
