import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
  code: 'GenAI.Type',
})
export class GenAiType {
  @GenericLookup({
    code: 'GenAI.Type.OpenAI',
  })
  static openAI: number;

  @GenericLookup({
    code: 'GenAI.Type.AzureOpenAI',
  })
  static azureOpenAI: number;
}
