<div
  ngbDropdown
  #selectorDropdown="ngbDropdown"
  [ngStyle]="styles"
  class="d-inline-block"
  (openChange)="onOpenChanged($event)"
  [container]="'body'"
  [dropdownClass]="dropdownClass">
  <button
    [ngClass]="
      useComboboxLook
        ? 'dropdown-selector-combobox ' + buttonCls
        : 'dropdown-selector-button ' + buttonCls
    "
    [disabled]="disabled"
    type="button"
    ngbDropdownToggle
    #selectorButton>
    <ng-container *ngIf="valueAsArray.length <= 0">
      <ng-container>
        <i *ngIf="showDropdownIcon" [class]="dropdownIconClass"></i>
        <span [ngClass]="labelCls ? labelCls : 'dropdown-button-label'">{{ emptyText }}</span>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="valueAsArray.length > 0">
      <i *ngIf="showDropdownIcon" [class]="dropdownIconClass"></i>
      <span [ngClass]="labelCls ? labelCls : 'dropdown-button-label'">
        <ng-container *ngTemplateOutlet="selectionTemplate; context: { selection: valueAsArray }">
        </ng-container>
      </span>
    </ng-container>
  </button>
  <div
    [ngClass]="
      multiple && selectorPosition == 'Right'
        ? 'dropdown-selector-menu'
        : 'dropdown-selector-menu-single'
    "
    ngbDropdownMenu
    [matchWidth]="selectorButton"
    [ngStyle]="listStyles">
    <div class="dropdown-selector-menu-content-wrapper">
      <div class="card dropdown-selector-card">
        <div class="card-header dropdown-selector-card-header" [hidden]="hideHeader">
          <ng-container *ngIf="selected.length > 0 && selectorPosition == 'Top'">
            <div class="row p-1">
              <div class="col-11 pb-2 top-selection-container">
                <div class="row">
                  <div class="col-6" *ngFor="let item of selected">
                    <div class="dropdown-selector-card-item-wrapper tag">
                      <span class="tag-text">
                        <ng-container
                          *ngTemplateOutlet="listSelectionTemplate; context: { selection: item }">
                        </ng-container>
                      </span>
                      <span
                        (click)="onClickRemoveItem($event, item)"
                        class="dropdown-selector-remove-selection-icon tagicon">
                        <i class="fas fa-times"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-1 pb-2 top-trash-container">
                <div class="row">
                  <ca-delete-icon
                    type="button"
                    (click)="onClearSelectionClick($event)"
                    title="{{ '::Clear' | abpLocalization }}"></ca-delete-icon>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="d-flex">
            <div class="p2 flex-grow-1">
              <div class="input-group">
                <i [ngClass]="'fas fa-search search-icon'"></i>
                <input
                  type="text"
                  class="form-control form-control-sm search-text"
                  placeholder="{{ 'AbpUi::Search' | abpLocalization }}"
                  [(ngModel)]="queryValue"
                  (keyup.enter)="onSearchRequest($event)"
                  #edtSearch />
                <div class="input-group-append">
                  <button
                    class="btn btn-sm btn-outline-dark"
                    type="button"
                    (click)="onRemoveFilterClick($event)">
                    <i class="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="p-2">
              <ng-container *ngTemplateOutlet="filterToolbarTemplate"></ng-container>
            </div>
          </div>
          <div
            *ngIf="information"
            title="{{ this.information }}"
            class="information text-danger text-start text-nowrap">
            <i class="fas fa-info ps-1 pt-1 fa-xs"></i
            ><span class="ps-1"
              ><small>{{ this.information }}</small>
            </span>
          </div>
        </div>
        <div class="card-body dropdown-selector-card-body">
          <span *ngIf="items?.length === 0">{{ '::NoItemsFound' | abpLocalization }}</span>
          <ul class="card-text dropdown-selector-card-text m-0 p-0">
            <li *ngFor="let item of items" (click)="onItemClick($event, item)">
              <div class="dropdown-selector-card-item-wrapper">
                <input
                  type="{{ multiple ? 'checkbox' : 'radio' }}"
                  [disabled]="checkItemDisability(item)"
                  [checked]="item.selected" />
                <span
                  [ngClass]="{ disabled: item.disabled }"
                  [ngbTooltip]="item.data.name"
                  container="body"
                  class="me-1 ms-1">
                  <ng-container *ngTemplateOutlet="itemTemplate; context: { item: item.data }">
                  </ng-container>
                </span>
              </div>
            </li>
          </ul>
        </div>
        <div class="card-footer text-muted dropdown-selector-card-footer" *ngIf="hasPaging">
          <div class="dropdown-selector-footer-content-wrapper not-rtl" dir="ltr" *ngIf="isRTL">
            <div class="dropdown-selector-footer-item">
              <button
                type="button"
                class="btn btn-sm btn-outline-dark dropdown-selector-paging-button last-page"
                (click)="onLastPageClick($event)">
                <i class="fas fa-step-backward"></i>
              </button>
            </div>
            <div class="dropdown-selector-footer-item">
              <button
                type="button"
                class="btn btn-sm btn-outline-dark dropdown-selector-paging-button next-page"
                (click)="onNextPageClick($event)">
                <i class="fas fa-backward"></i>
              </button>
            </div>
            <div class="dropdown-selector-footer-item">
              <input
                type="text"
                class="form-control form-control-sm dropdown-selector-paging-input page-count"
                readonly
                [value]="pageCount" />
            </div>
            <div class="dropdown-selector-paging-separator">/</div>
            <div class="dropdown-selector-footer-item">
              <input
                type="text"
                class="form-control form-control-sm dropdown-selector-paging-input current-page-input"
                [(ngModel)]="currentPageInputValue"
                (keyup.enter)="onPageInputChange($event)"
                #edtCurrentPage />
            </div>
            <div class="dropdown-selector-footer-item">
              <button
                class="btn btn-sm btn-outline-dark dropdown-selector-paging-button previous-page"
                type="button"
                (click)="onPreviousPageClick($event)">
                <i class="fas fa-forward"></i>
              </button>
            </div>
            <div class="dropdown-selector-footer-item">
              <button
                class="btn btn-sm btn-outline-dark dropdown-selector-paging-button first-page"
                type="button"
                (click)="onFirstPageClick($event)">
                <i class="fas fa-step-forward"></i>
              </button>
            </div>
          </div>
          <div class="dropdown-selector-footer-content-wrapper yes-rtl" dir="ltr" *ngIf="!isRTL">
            <div class="dropdown-selector-footer-item">
              <button
                class="btn btn-sm btn-outline-dark dropdown-selector-paging-button first-page"
                type="button"
                (click)="onFirstPageClick($event)">
                <i class="fas fa-step-backward"></i>
              </button>
            </div>
            <div class="dropdown-selector-footer-item">
              <button
                class="btn btn-sm btn-outline-dark dropdown-selector-paging-button previous-page"
                type="button"
                (click)="onPreviousPageClick($event)">
                <i class="fas fa-backward"></i>
              </button>
            </div>
            <div class="dropdown-selector-footer-item">
              <input
                type="text"
                class="form-control form-control-sm dropdown-selector-paging-input current-page-input"
                [(ngModel)]="currentPageInputValue"
                (keyup.enter)="onPageInputChange($event)"
                #edtCurrentPage />
            </div>
            <div class="dropdown-selector-paging-separator">/</div>
            <div class="dropdown-selector-footer-item">
              <input
                type="text"
                class="form-control form-control-sm dropdown-selector-paging-input page-count"
                readonly
                [value]="pageCount" />
            </div>
            <div class="dropdown-selector-footer-item">
              <button
                type="button"
                class="btn btn-sm btn-outline-dark dropdown-selector-paging-button next-page"
                (click)="onNextPageClick($event)">
                <i class="fas fa-forward"></i>
              </button>
            </div>
            <div class="dropdown-selector-footer-item">
              <button
                type="button"
                class="btn btn-sm btn-outline-dark dropdown-selector-paging-button last-page"
                (click)="onLastPageClick($event)">
                <i class="fas fa-step-forward"></i>
              </button>
            </div>
          </div>
          <small class="dropdown-selector-footer-item paging-info m-2">
            {{
              '::PaginationCounterText'
                | abpLocalization
                  : lowerBound.toString()
                  : upperBound.toString()
                  : totalCount.toString()
            }}
          </small>
        </div>
      </div>
      <ng-container *ngIf="multiple && selectorPosition == 'Right'">
        <div
          class="card text-center"
          style="flex: 1; border-left: 1px solid rgba(51, 51, 51, 0.05); margin-bottom: 0px">
          <div class="card-header dropdown-selector-selection-card-header">
            {{ '::SelectedRecords' | abpLocalization }} ({{ this.selected.length }})
          </div>
          <div class="card-body dropdown-selector-card-body">
            <ul class="card-text dropdown-selector-card-text">
              <li *ngFor="let item of selected">
                <div class="dropdown-selector-card-item-wrapper">
                  <span
                    (click)="onClickRemoveItem($event, item)"
                    class="dropdown-selector-remove-selection-icon">
                    <i class="fas fa-times"></i>
                  </span>
                  <span>
                    <ng-container *ngTemplateOutlet="itemTemplate; context: { item: item }">
                    </ng-container>
                  </span>
                </div>
              </li>
            </ul>
          </div>
          <div class="card-footer text-muted dropdown-selector-selection-card-footer">
            <button
              class="btn btn-sm btn-outline-dark dropdown-selector-clear-selection-button"
              type="button"
              (click)="onClearSelectionClick($event)">
              {{ '::Clear' | abpLocalization }}
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
