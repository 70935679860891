import { Pipe, PipeTransform } from '@angular/core';
import { NumberHelper } from '../helpers/number-helper';

@Pipe({ name: 'caNumberPipe' })
export class CANumberPipe implements PipeTransform {
  constructor(private numberHelper: NumberHelper) { }

  transform(value: string | number): string {
    if (value == null) {
      return '-';
    }

    if (typeof value === 'string') {
      value = parseFloat(value);
    }
    else {
      value = value;
    }

    return this.numberHelper.isNumberConvergingZero(value) ? '≈0' : this.numberHelper.isInt(value) ? value.toString() : value.toFixed(2);
  }
}
