import { Api } from 'src/core/decorators/dto/api.decorator';
import { QueryDto } from '../query/query.dto';
import { DtoHasApi } from '../shared/dto-has-api.model';
import { FormSectionDto } from './form-section.dto';

@Api('api/app/quality/form-version')
export class FormVersionDto extends DtoHasApi {
  id: number;
  formId: number;
  version: number;
  minScore: number;
  maxScore: number;
  isNormalized: boolean;
  isPublished: boolean;
  queryId: number;
  query: QueryDto;
  formSections: FormSectionDto[];
  formName: string;
  newVersion: boolean;
  initialScore: number;
}
