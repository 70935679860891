import {
  Component,
  Input,
  OnInit,
  forwardRef,
  Output,
  EventEmitter,
  ContentChildren,
  QueryList,
  AfterContentInit,
  AfterViewInit,
  OnDestroy,
  ElementRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectItemModel } from '../../models/select-item.model';
import { OptionComponent } from '../option/option.component';

@Component({
  selector: 'ca-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SelectComponent),
    },
  ],
})
export class SelectComponent
  implements OnInit, AfterViewInit, AfterContentInit, OnDestroy, ControlValueAccessor
{
  @ContentChildren(OptionComponent) options: QueryList<OptionComponent>;

  @Output()
  change: EventEmitter<any> = new EventEmitter();

  @Input()
  class: string;

  @Input()
  style: string;

  @Input()
  appendTo: string = 'body';

  @Input()
  items: SelectItemModel[];

  @Input()
  disabled: boolean = false;

  @Input()
  placeholder: string;

  @Input()
  multiple: boolean = false;

  value: any;
  changes: MutationObserver;
  classList: string = '';

  constructor(private elementRef: ElementRef) {}

  writeValue(obj: any): void {
    obj = obj ? obj : [];
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onChange(args: any) {}

  onTouched() {}

  onChanged(args: any): void {
    this.onChange(args);
    this.change.emit(args);
  }

  ngOnInit(): void {}

  getItemLabel(itemId) {
    if (itemId > 0) {
      return this.items.find(f => f.data == itemId).label;
    }
  }

  ngAfterViewInit(): void {
    this.changes = new MutationObserver((mutations: MutationRecord[]) => {
      mutations.forEach((mutation: MutationRecord) => {
        this.classList = this.elementRef.nativeElement.classList;
      });
    });

    this.changes.observe(this.elementRef.nativeElement, {
      attributeFilter: ['class'],
    });
  }

  ngAfterContentInit(): void {
    this.processOptionComponents();
    this.options.changes.subscribe(() => {
      this.processOptionComponents();
    });
  }

  ngOnDestroy(): void {
    this.changes.disconnect();
  }

  private processOptionComponents() {
    var optionComponents = this.options.toArray();

    if (!this.items || this.items.length > 0) {
      this.items = [];
    }

    optionComponents.forEach(o => {
      if (!this.items.some(i => i.data === o.value)) {
        this.items.push({ data: o.value, label: o.label });
      }
    });
  }
}
