<div>
  <h2>{{ 'Settings::RealTime' | abpLocalization }}</h2>
</div>
<br />
<hr />
<form
  *ngIf="realTimeFeatureEnabled"
  [formGroup]="realTimeSettingsForm"
  (ngSubmit)="onSubmitAnalysisSettings()">
  <div formGroupName="scenarioSettingsForm">
    <ng-template #scenarioPopContent>
      <p class="text-start h4">
        {{ 'Settings::UsageValueRecommendation' | abpLocalization }}
      </p>
      <div class="text-start">{{ 'Settings::ScenarioSpeechRate' | abpLocalization }}: 12-18</div>
      <div class="text-start">{{ 'Settings::ScenarioMonotonicity' | abpLocalization }}: 45-65</div>
    </ng-template>
    <h4>
      {{ 'Settings::ScenarioSettings' | abpLocalization }}
      <i class="ms-2 fas fa-info-circle" [ngbTooltip]="scenarioPopContent" container="body"></i>
    </h4>
    <div class="row form-row">
      <label class="col-form-label indicates-required-field">
        {{ ('Settings::ScenarioSpeechRate' | abpLocalization) + ' ' }}</label
      >
      <div class="range-input">
        <div class="col-3 first">
          <input
            type="number"
            min="0"
            class="form-control form-control-sm"
            id="minSpeechRate"
            formControlName="minSpeechRate" />
        </div>
        <div class="col-3 second">
          <input
            type="number"
            min="0"
            class="form-control form-control-sm"
            id="maxSpeechRate"
            formControlName="maxSpeechRate" />
        </div>
      </div>
    </div>
    <div class="row form-row">
      <label for="minMonotonicity" class="col-form-label indicates-required-field">
        {{ ('Settings::ScenarioMonotonicity' | abpLocalization) + ' ' }}</label
      >
      <div class="range-input">
        <div class="col-3 first">
          <input
            type="number"
            min="0"
            max="100"
            class="form-control form-control-sm"
            id="minMonotonicity"
            formControlName="minMonotonicity" />
        </div>
        <div class="col-3 second">
          <input
            type="number"
            min="0"
            max="100"
            class="form-control form-control-sm"
            id="maxMonotonicity"
            formControlName="maxMonotonicity" />
        </div>
      </div>
    </div>
  </div>

  <button type="button" type="submit" class="btn btn-primary mt-3 mb-3">
    <i class="me-1 fas fa-save"></i>{{ 'AbpUi::Save' | abpLocalization }}
  </button>
</form>
