<ca-clickable-link
  [innerHtml]="
    'QualityManagement::EvaluationResultAgentNotificationMessage'
      | abpLocalization
        : payloadData?.conversationId
        : payloadData?.score.toFixed(1)
        : payloadData?.maxScore.toFixed(1)
  "
  [targetLink]="['/quality/evaluation-result']"
  [params]="{
    conversationId: payloadData?.conversationId,
    evaluationMasterId: payloadData?.evaluationMasterId,
    referrer: 'evaluation-result'
  }">
</ca-clickable-link>
