import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfigurationSettingsDto } from 'src/core/models/configuration-setting/configuration-settings.dto';
import { ConfigurationSettingsService } from 'src/core/services/configuration-settings/configuration-settings.service';
import { StringValidator } from 'src/core/validators/shared/string.validator';

@Component({
  selector: 'ca-zendesk-integration-settings',
  templateUrl: './zendesk-integration-settings.component.html',
  styleUrls: ['./zendesk-integration-settings.component.scss'],
})
export class ZendeskIntegrationSettingsComponent implements OnInit {
  form: FormGroup;

  private readonly prefix = 'ZendeskIntegration';
  private readonly sourceName = `${this.prefix}.SourceName`;
  private readonly userName = `${this.prefix}.UserName`;
  private readonly password = `${this.prefix}.Password`;
  private readonly migrationStartDate = `${this.prefix}.MigrationStartDate`;
  private readonly tenantName = `${this.prefix}.TenantName`;

  constructor(
    private fb: FormBuilder,
    private config: ConfigStateService,
    private settingService: ConfigurationSettingsService,
    private toastr: ToasterService,
    private localizationService: LocalizationService
  ) {
    this.form = this.fb.group({
      sourceName: this.fb.group({
        name: this.sourceName,
        value: [
          this.config.getSetting(this.sourceName),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      tenantName: this.fb.group({
        name: this.tenantName,
        value: [
          this.config.getSetting(this.tenantName),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      userName: this.fb.group({
        name: this.userName,
        value: [
          this.config.getSetting(this.userName),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      password: this.fb.group({
        name: this.password,
        value: [
          this.config.getSetting(this.password),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      migrationStartDate: this.fb.group({
        name: this.migrationStartDate,
        value: [
          this.config.getSetting(this.migrationStartDate),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
    });
  }

  ngOnInit(): void {}

  onSubmitForm(eventArgs): void {
    if (!this.form.valid) {
      return;
    }

    const settings: ConfigurationSettingsDto[] = [];

    settings.push({
      settingName: this.form.get('sourceName').get('name').value,
      settingValue: this.form.get('sourceName').get('value').value,
    });

    settings.push({
      settingName: this.form.get('tenantName').get('name').value,
      settingValue: this.form.get('tenantName').get('value').value,
    });

    settings.push({
      settingName: this.form.get('userName').get('name').value,
      settingValue: this.form.get('userName').get('value').value,
    });

    settings.push({
      settingName: this.form.get('password').get('name').value,
      settingValue: this.form.get('password').get('value').value,
    });

    settings.push({
      settingName: this.form.get('migrationStartDate').get('name').value,
      settingValue: this.form.get('migrationStartDate').get('value').value,
    });
    this.settingService.saveSetting(settings).subscribe(res => {
      this.toastr.success(
        this.localizationService.instant('AbpSettingManagement::SuccessfullySaved')
      );
    });
  }
}
