import { EventEmitter, Injectable, Output } from '@angular/core';
import { GridConfig } from '../models/grid-config.model';
import { Store } from '@ngxs/store';
import { ColumnConfiguratorModuleStateModel } from '../state/column-configurator-module.state-model';
import { ColumnConfiguratorModuleState } from '../state/column-configurator-module.state';
import { MatTableColumnDefinitionModel } from 'src/core/models/mat-table/mat-table-column-definition.model';
import { GridConfigChange } from '../state/column-configurator-module.actions';

@Injectable({
  providedIn: 'root',
})
export class ColumnConfiguratorService {
  @Output()
  configuratorRequested: EventEmitter<{
    gridId: string;
  }> = new EventEmitter();

  @Output()
  configChanges: EventEmitter<{
    gridId: string;
    columns: MatTableColumnDefinitionModel[];
  }> = new EventEmitter();

  @Output()
  columnWidthChanges: EventEmitter<{
    gridId: string;
    columnId: string;
    width: string;
    newWidth: string;
  }> = new EventEmitter();

  private readonly minimumColumnWidth: number = 48;
  private defaultColumnDefinitions: Map<string, MatTableColumnDefinitionModel[]> = new Map<
    string,
    MatTableColumnDefinitionModel[]
  >();
  private columnDefinitions: Map<string, MatTableColumnDefinitionModel[]> = new Map<
    string,
    MatTableColumnDefinitionModel[]
  >();
  private displayedColumns: Map<string, string[]> = new Map<string, string[]>();

  constructor(private store: Store) {}

  connect(
    gridId: string,
    defaultColumnDefinitions: MatTableColumnDefinitionModel[],
    displayedColumns: string[]
  ): void {
    // display locked columns first
    defaultColumnDefinitions.sort(this.compareColumns);

    const originalColumnDefinitions = new Array(...defaultColumnDefinitions);

    this.displayedColumns.set(gridId, displayedColumns);
    this.defaultColumnDefinitions.set(gridId, originalColumnDefinitions);

    defaultColumnDefinitions.splice(0, defaultColumnDefinitions.length);

    const gridConfig = this.getGridConfig(gridId);

    gridConfig.columns.forEach(columnConfig => {
      const columnDefinitionIdx = originalColumnDefinitions.findIndex(
        x => x.columnName === columnConfig.id
      );
      const columnDefinition = originalColumnDefinitions[columnDefinitionIdx];
      const newColumnDefinition = Object.assign({}, columnDefinition);

      newColumnDefinition.visible = columnConfig.visible;
      newColumnDefinition.width = columnConfig.width;

      defaultColumnDefinitions.push(newColumnDefinition);
    });

    this.columnDefinitions.set(gridId, defaultColumnDefinitions);

    this.setDisplayedColumns(gridId);
  }

  saveConfig(gridId: string): void {
    const columnDefinitions = this.columnDefinitions.get(gridId);
    const gridConfig = this.convertColumnDefinitionModelToGridConfig(gridId, columnDefinitions);

    const action = new GridConfigChange(gridConfig);
    this.store.dispatch(action);
  }

  showConfigurator(gridId: string): void {
    this.configuratorRequested.emit({ gridId: gridId });
  }

  getGridConfig(gridId: string): GridConfig {
    const state = this.store.selectSnapshot<ColumnConfiguratorModuleStateModel>(
      ColumnConfiguratorModuleState
    );

    const configIdx = state.grids.findIndex(x => x.id == gridId);
    let result = configIdx > -1 ? state.grids[configIdx] : null;

    if (result === null) {
      result = this.convertColumnDefinitionModelToGridConfig(
        gridId,
        this.defaultColumnDefinitions.get(gridId)
      );
    } else {
      // Set headers from default config for multilangueage issues
      result.columns.forEach(cfg => {
        const defaultColumnDefinitions = this.defaultColumnDefinitions.get(gridId);
        const columnDefinition = defaultColumnDefinitions.find(x => x.columnName === cfg.id);

        cfg.header = columnDefinition.header;
      });
    }

    return result;
  }

  resetConfig(gridId: string): void {
    const defaultColumnDefinitions = this.defaultColumnDefinitions.get(gridId);
    const columnDefinitions = this.columnDefinitions.get(gridId);

    columnDefinitions.forEach(columnDefinition => {
      const defaultColumnDefinitionIdx = defaultColumnDefinitions.findIndex(
        x => x.columnName === columnDefinition.columnName
      );
      const defaultColumnDefinition = defaultColumnDefinitions[defaultColumnDefinitionIdx];

      columnDefinition.width = defaultColumnDefinition.width;
    });

    columnDefinitions.splice(0, columnDefinitions.length);

    defaultColumnDefinitions.forEach(columnDefinition => {
      const newColumnDefinition = Object.assign({}, columnDefinition);

      columnDefinitions.push(newColumnDefinition);
    });

    this.columnDefinitions.set(gridId, columnDefinitions);

    this.setDisplayedColumns(gridId);

    this.saveConfig(gridId);
  }

  getColumnDefinitions(gridId: string): MatTableColumnDefinitionModel[] {
    return this.columnDefinitions.get(gridId);
  }

  convertColumnDefinitionModelToGridConfig(
    gridId: string,
    columnDefinitions: MatTableColumnDefinitionModel[]
  ): GridConfig {
    const gridConfig: GridConfig = {
      id: gridId,
      columns: [],
    };

    columnDefinitions.forEach((col, idx) => {
      gridConfig.columns.push({
        id: col.columnName,
        header: col.header,
        locked: col.locked,
        visible: col.visible,
        width: col.width,
      });
    });

    return gridConfig;
  }

  saveVisibleColumns(gridId: string, visibleColumnIds: string[]): void {
    const defaultColumnDefinitions = this.defaultColumnDefinitions.get(gridId);
    const columnDefinitions = this.columnDefinitions.get(gridId);
    const copyOfColumnDefinitions = new Array(...columnDefinitions);

    columnDefinitions.splice(0, columnDefinitions.length);

    visibleColumnIds.forEach(id => {
      const columnDefinitionIdx = copyOfColumnDefinitions.findIndex(x => x.columnName === id);
      const columnDefinition = copyOfColumnDefinitions[columnDefinitionIdx];
      const newColumnDefinition = Object.assign({}, columnDefinition);

      newColumnDefinition.visible = true;

      columnDefinitions.push(newColumnDefinition);
    });

    defaultColumnDefinitions
      .map(x => x.columnName)
      .filter(x => !visibleColumnIds.includes(x))
      .forEach(id => {
        const columnDefinitionIdx = copyOfColumnDefinitions.findIndex(x => x.columnName === id);
        const columnDefinition = copyOfColumnDefinitions[columnDefinitionIdx];
        const newColumnDefinition = Object.assign({}, columnDefinition);

        newColumnDefinition.visible = false;

        columnDefinitions.push(newColumnDefinition);
      });

    this.columnDefinitions.set(gridId, columnDefinitions);

    this.setDisplayedColumns(gridId);

    this.saveConfig(gridId);
  }

  convertGridConfigToColumnDefinitionModel(
    gridConfig: GridConfig,
    columnDefinitions: MatTableColumnDefinitionModel[]
  ): MatTableColumnDefinitionModel[] {
    const result: MatTableColumnDefinitionModel[] = [];

    gridConfig.columns.forEach(value => {
      const idx = columnDefinitions.findIndex(x => x.columnName === value.id);

      if (idx > -1) {
        const def = Object.assign({}, columnDefinitions[idx]);

        def.width = value.width;
        def.visible = value.visible;

        result.push(def);
      }
    });

    return result;
  }

  changeColumnWidth(gridId: string, columnId: string, distance: number): void {
    const gridConfig = this.getGridConfig(gridId);
    const columnConfigIdx = gridConfig.columns.findIndex(x => x.id === columnId);
    const columnConfig = gridConfig.columns[columnConfigIdx];
    const columnDefinitions = this.columnDefinitions.get(gridId);
    const columnDefinitionIdx = columnDefinitions.findIndex(x => x.columnName === columnId);

    const width: number = Number(columnConfig.width.replace('px', ''));
    let newWidth = width + distance;

    newWidth = newWidth < this.minimumColumnWidth ? this.minimumColumnWidth : newWidth;

    columnDefinitions[columnDefinitionIdx].width = `${newWidth}px`;
    columnConfig.width = `${newWidth}px`;

    this.saveConfig(gridId);
  }

  private setDisplayedColumns(gridId: string): void {
    const columnDefinitions = this.columnDefinitions.get(gridId);
    const displayedColumns = this.displayedColumns.get(gridId);

    displayedColumns.splice(0, displayedColumns.length);

    // Added mandatory timeout to trigger change detection for columns' visibility and width.
    setTimeout(() => {
      columnDefinitions
        .filter(col => col.visible)
        .forEach(col => {
          displayedColumns.push(col.columnName);
        });
    }, 1);
  }

  private compareColumns(
    a: MatTableColumnDefinitionModel,
    b: MatTableColumnDefinitionModel
  ): number {
    if (a.locked && !b.locked) {
      return -1;
    } else if (!a.locked && b.locked) {
      return 1;
    }

    return 0;
  }
}
