import { LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { map, take, takeUntil } from 'rxjs/operators';
import { CallLanguageCodeDto } from 'src/core/models/call/call-language-code.dto';
import { ConfigurationSettingsDto } from 'src/core/models/configuration-setting/configuration-settings.dto';
import { CallAgentChannel } from 'src/core/models/generic-lookup-type/call/call-agent-channel.glt';
import { GenericLookupDto } from 'src/core/models/generic-lookup/generic-lookup.dto';
import { ConfigurationSettingsService } from 'src/core/services/configuration-settings/configuration-settings.service';
import { GenericLookupTypeState } from 'src/core/states/generic-lookup-type/generic-lookup-type.state';
import { IntegerValidator } from 'src/core/validators/shared/integer.validator';
import { GlobalSettingsService } from 'src/core/services/settings/global-settings.service';
import { DateFormatConstants } from 'src/core/constants/dateformat.constant';
import { TimeZoneConstants } from 'src/core/constants/timezone.constant';
import { FeatureConstants } from 'src/core/constants/feature-constant';
import { FeatureService } from 'src/core/services/feature/feature.service';
import { KeyValue } from '@angular/common';
import { ObjectHelper } from 'src/core/helpers/object.helper';
import { MixedLanguageType } from 'src/core/models/generic-lookup-type/conversation/conversation-mixed-language-type.glt';
import { RoleHelper } from 'src/core/helpers/role-helper';
import { HasSubscription } from 'src/ca-shared/ca-shared.module';
import { AutoUnsubscribe } from 'src/core/decorators/auto-unsubscribe.decorator';

@Component({
  selector: 'ca-global-settings',
  templateUrl: './global-settings.component.html',
  styleUrls: ['./global-settings.component.scss'],
})
@AutoUnsubscribe()
export class GlobalSettingsComponent extends HasSubscription implements OnInit {
  settingsForm: FormGroup;
  globalSettingDtos: ConfigurationSettingsDto[] = [];
  channelTypes: GenericLookupDto[];
  systemStartDate: any;
  mixedLanguageType: string = 'SixLanguagesMulti';
  mixedLanguageTypes: GenericLookupDto[];
  systemLangCode: string;
  systemLangCodeTemp: string;
  otherLangCodes: string[];
  langCodesSettingValue: string[] = [];
  langCodesTemp: string[] = [];
  systemTimeZone: string;
  systemDateFormat: string;
  inboundAgentChannel: string;
  outboundAgentChannel: string;
  domainName: string;
  ldapCertificateName: string;
  maximumAnalysisTime: number;
  languages: CallLanguageCodeDto[] = [{
    name: "dummy",
    code: "dummy",
    isVisible: false,
    disabled: false,
  }];
  otherLanguagesDataSource: CallLanguageCodeDto[] = [];
  timeZones: any;
  userFeedbackReceiverEmail: string;
  userFeedbackFeatureEnabled = false;
  analyticsFeatureEnabled = false;
  idleTimeoutEnabled = false;
  idleTimeoutMinute: number;
  dateFormats: string[];
  testEmailAddress: string;
  autoPlayEnabled = true;
  corsOriginForEmbeddedAccess: string;
  isSuperAdminUser: boolean = false;
  dynamicDataMaskingEnabled = false;
  dynamicDataMaskingUrl: string;

  get getCurrentSystemLanguage() {
    return this.languages?.find(x => x.code == this.systemLangCode)?.name;
  }

  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  };

  constructor(
    private globalSettingsService: GlobalSettingsService,
    private settingService: ConfigurationSettingsService,
    private toastr: ToasterService,
    private localizationService: LocalizationService,
    private store: Store,
    private featureService: FeatureService,
    private roleHelper: RoleHelper,
    private fb: FormBuilder
  ) {
    super();

    this.userFeedbackFeatureEnabled = this.featureService.isEnabled(FeatureConstants.UserFeedback);
    this.analyticsFeatureEnabled = this.featureService.isEnabled(FeatureConstants.Analytics);

    this.systemStartDate = this.globalSettingsService.systemStartDate.toLocaleDateString('en-US');

    this.mixedLanguageType = this.globalSettingsService.mixedLanguageType;
    this.store
      .select(GenericLookupTypeState.getGenericLookups)
      .pipe(takeUntil(this.autoUnsubscribeNotifier))
      .pipe(map(filterFn => filterFn(MixedLanguageType)))
      .subscribe(result => {
        this.mixedLanguageTypes = this.getMixedLanguageOrder(result);
      });
    this.systemLangCode = this.globalSettingsService.systemLanguageCode;
    this.otherLangCodes = this.globalSettingsService.otherLangCodes
      .split(';')
      .filter(x => x.length > 0);
    this.systemTimeZone = this.globalSettingsService.systemTimeZone;
    this.systemDateFormat = this.globalSettingsService.systemDateFormat.replace(/M/g, 'm');
    this.inboundAgentChannel = this.globalSettingsService.inboundAgentChannel;
    this.outboundAgentChannel = this.globalSettingsService.outboundAgentChannel;
    this.domainName = this.globalSettingsService.domainName;
    this.ldapCertificateName = this.globalSettingsService.ldapCertificateName;
    this.userFeedbackReceiverEmail = this.globalSettingsService.userFeedbackReceiverEmail;
    this.maximumAnalysisTime = this.globalSettingsService.maximumAnalysisTime;
    this.idleTimeoutEnabled = this.globalSettingsService.idleTimeoutEnabled;
    this.idleTimeoutMinute = this.globalSettingsService.idleTimeoutMinute;
    this.autoPlayEnabled = this.globalSettingsService.autoPlayEnabled;
    this.corsOriginForEmbeddedAccess = this.globalSettingsService.corsOriginForEmbeddedAccess;
    this.dynamicDataMaskingUrl = this.globalSettingsService.dynamicDataMaskingUrl;
    this.dynamicDataMaskingEnabled = this.globalSettingsService.dynamicDataMaskingEnabled;
    this.settingsForm = fb.group({
      systemStartDate: null,
      mixedLanguageType: 'SixLanguagesMulti',
      systemLangCode: null,
      otherLangCodes: [],
      systemTimeZone: null,
      systemDateFormat: null,
      inboundAgentChannel: null,
      outboundAgentChannel: null,
      domainName: null,
      ldapCertificateName: null,
      idleTimeoutEnabled: null,
      idleTimeoutMinute: [
        null,
        {
          validators: [Validators.required, IntegerValidator.minMax(1, 30)],
        },
      ],
      userFeedbackReceiverEmail: [
        null,
        {
          validators: [Validators.email],
        },
      ],
      maxAnalysisTime: [
        null,
        {
          validators: [IntegerValidator.minMax(0, 100)],
        },
      ],
      testEmailAddress: [
        null,
        {
          validators: [Validators.email],
        },
      ],
      autoPlayEnabled: null,
      corsOriginForEmbeddedAccess: [
        null,
        {
          validators: [],
        },
      ],
      dynamicDataMaskingEnabled: null,
      dynamicDataMaskingUrl: null,
    });

    this.store
      .select(GenericLookupTypeState.getGenericLookups)
      .pipe(takeUntil(this.autoUnsubscribeNotifier))
      .pipe(map(filterFn => filterFn(CallAgentChannel)))
      .subscribe(result => {
        this.channelTypes = result;
      });

    if (this.otherLangCodes.length > 0) {
      this.settingsForm.get('otherLangCodes').setValue(this.otherLangCodes);
    }

    this.langCodesTemp = [...this.otherLangCodes];
    this.langCodesSettingValue = [...this.otherLangCodes];
    if (this.systemLangCode) {
      this.systemLangCodeTemp = this.systemLangCode;
      this.langCodesSettingValue.push(this.systemLangCode);
      this.langCodesTemp.push(this.systemLangCode);
    }

    this.settingService
      .getLanguageCodeList()
      .pipe(take(1))
      .subscribe(res => {
        this.languages = res.filter(x => !x.code.includes(';'));
        this.languages.sort((x, y) => (x.name > y.name ? 1 : -1));
        this.resetOtherLanguagesDataSource();
        if (this.systemLangCode) {
          this.setSelectedOtherLanguagesItem(this.systemLangCode);
        }
      });

    this.settingsForm.get('systemLangCode').valueChanges.subscribe(selectedValue => {
      if (selectedValue) {
        this.setSelectedOtherLanguagesItem(selectedValue);
        if (selectedValue !== this.systemLangCodeTemp) {
          if (this.langCodesSettingValue.filter(x => x === this.systemLangCodeTemp).length === 0) {
            this.langCodesTemp = this.langCodesTemp.filter(x => x !== this.systemLangCodeTemp);
          }
          if (this.langCodesTemp.filter(x => x === selectedValue).length === 0) {
            this.langCodesTemp.push(selectedValue);
          }
          this.systemLangCodeTemp = selectedValue;
          this.resetOtherLanguagesDataSource();
        }
      }
    });

    this.dateFormats = DateFormatConstants.dateFormats;
    this.timeZones = TimeZoneConstants.timeZones;
    this.isSuperAdminUser = this.roleHelper.isSuperAdminUser();
  }

  removeFromSelectedOtherLanguages(languageCode: string) {
    let selectedItems = this.settingsForm.get('otherLangCodes').value;

    if (selectedItems) {
      const indexToRemoveFromSelected = selectedItems.findIndex(i => i === languageCode);
      if (indexToRemoveFromSelected > -1) {
        selectedItems.splice(indexToRemoveFromSelected, 1);
        this.settingsForm.get('otherLangCodes').setValue(selectedItems);
      }
    }
  }

  setSelectedOtherLanguagesItem(languageCode: string) {
    let selectedItems = this.settingsForm.get('otherLangCodes').value ?? [];
    const indexToSelectFromSelected = selectedItems.findIndex(i => i === languageCode);
    if (indexToSelectFromSelected === -1) {
      const indexToSelectFromDataSource = this.otherLanguagesDataSource.findIndex(
        d => d.code === languageCode
      );
      if (indexToSelectFromDataSource > -1) {
        selectedItems.push(languageCode);
        this.settingsForm.get('otherLangCodes').setValue(selectedItems);
      }
    }
  }

  resetOtherLanguagesDataSource() {
    if (this.languages) {
      this.otherLanguagesDataSource = ObjectHelper.deepCopy(this.languages);
      this.otherLanguagesDataSource.forEach(data => {
        if (this.langCodesTemp.filter(x => x === data.code).length > 0) {
          data.disabled = true;
        }
      });
    }
  }

  isDisabled(code: string) {
    let selectedItems = this.settingsForm.get('otherLangCodes').value;
    if (selectedItems) {
      let result = selectedItems.includes(code);
      if (result) {
        const hasLangCode = this.langCodesTemp.find(p => p === code);
        if (hasLangCode && hasLangCode.length > 0) {
          return true;
        }
      }
      return false;
    }
    return false;
  }

  ngOnInit(): void { }

  onSubmitSettings() {
    if (this.settingsForm.invalid) {
      return;
    }
    this.saveSettings();
  }

  saveSettings() {
    let otherLangCodes = this.settingsForm.get('otherLangCodes').value ?? [];
    otherLangCodes = otherLangCodes.filter(x => x != this.settingsForm.get('systemLangCode').value);
    //TO-DO: there should be more effective solution for the static calculations.
    this.globalSettingDtos = new Array();
    this.globalSettingDtos.push(
      {
        settingName: 'Global.MixedLanguageType',
        settingValue: this.mixedLanguageType.toString(),
      },
      {
        settingName: 'Global.SystemLanguageCode',
        settingValue: this.systemLangCode,
      },
      {
        settingName: 'Global.OtherLanguageCode',
        settingValue: otherLangCodes?.join(';'),
      },
      {
        settingName: 'Global.QueryLanguageCodes',
        settingValue: otherLangCodes?.join(','),
      },
      {
        settingName: 'Global.InboundAgentChannel',
        settingValue: this.inboundAgentChannel.toString(),
      },
      {
        settingName: 'Global.OutboundAgentChannel',
        settingValue: this.outboundAgentChannel.toString(),
      },
      {
        settingName: 'Global.DomainName',
        settingValue: this.domainName.toString(),
      },
      {
        settingName: 'Global.LdapCertificateName',
        settingValue: this.ldapCertificateName.toString(),
      },
      {
        settingName: 'Global.UserFeedbackReceiverEmail',
        settingValue: this.userFeedbackReceiverEmail.toString(),
      },
      {
        settingName: 'Global.MaximumAnalysisTime',
        settingValue: this.maximumAnalysisTime.toString(),
      },
      {
        settingName: 'Global.SystemDateFormat',
        settingValue: this.systemDateFormat.replace(/m/g, 'M'),
      },
      {
        settingName: 'Global.SystemTimeZone',
        settingValue: this.systemTimeZone,
      },
      {
        settingName: 'Global.IdleTimeoutEnabled',
        settingValue: this.idleTimeoutEnabled.toString(),
      },
      {
        settingName: 'Global.IdleTimeoutMinute',
        settingValue: this.idleTimeoutMinute.toString(),
      },
      {
        settingName: 'Global.AutoPlayEnabled',
        settingValue: this.autoPlayEnabled.toString(),
      },
      {
        settingName: 'Auth.CorsOriginForEmbeddedAccess',
        settingValue: this.corsOriginForEmbeddedAccess,
      },
      {
        settingName: 'Global.DynamicDataMaskingEnabled',
        settingValue: this.dynamicDataMaskingEnabled.toString(),
      },
      {
        settingName: 'Global.DynamicDataMaskingUrl',
        settingValue: this.dynamicDataMaskingUrl,
      }
    );

    this.settingService
      .saveSetting(this.globalSettingDtos)
      .pipe(take(1))
      .subscribe(res => {
        let oldLanguageCodes = [...this.langCodesSettingValue];
        let newLangaugeCodes = [...this.settingsForm.get('otherLangCodes').value] ?? [];
        let difference = newLangaugeCodes.filter(x => !oldLanguageCodes.includes(x));
        if (difference && difference.length > 0) {
          this.globalSettingsService
            .generatePredefinedItems(difference.join(';'))
            .pipe(take(1))
            .subscribe({
              next: res => {
                if (res != null && res.length > 0) {
                  this.toastr.warn(res);
                } else {
                  this.toastr.success(
                    this.localizationService.instant(
                      'Settings::Global.SuccessfullyGeneratePredefinedForSecondaryLang'
                    )
                  );
                }
              },
              error: error => {
                this.toastr.warn(error.error.text);
              },
            });
        }

        this.langCodesSettingValue = [...this.settingsForm.get('otherLangCodes').value];
        this.langCodesTemp = [...this.settingsForm.get('otherLangCodes').value];
        this.resetOtherLanguagesDataSource();
        this.toastr.success(
          this.localizationService.instant('AbpSettingManagement::SuccessfullySaved')
        );
      });
  }

  sendTestEmail() {
    const isValid = this.settingsForm.get('testEmailAddress').valid;
    if (!isValid) {
      return;
    }

    this.globalSettingsService
      .sendTestMail(this.testEmailAddress)
      .pipe(take(1))
      .subscribe(res => {
        if (res) {
          this.toastr.success(
            this.localizationService.instant('Settings::Global.SuccessfullySendTestEmail')
          );
        }
      });
  }

  getMixedLanguageOrder(items: GenericLookupDto[]) {
    const result = [];

    if (items.length > 0) {
      const order1 = items.filter(x => x.code === 'Conversation.MixedLanguageType.SystemLanguage');
      const order2 = items.filter(
        x => x.code === 'Conversation.MixedLanguageType.SixLanguagesMulti'
      );
      const order3 = items.filter(
        x => x.code === 'Conversation.MixedLanguageType.FourLanguagesMulti'
      );
      const order4 = items.filter(x => x.code === 'Conversation.MixedLanguageType.EnglishArabic');
      const order5 = items.filter(x => x.code === 'Conversation.MixedLanguageType.DutchFrench');
      const order6 = items.filter(
        x => x.code === 'Conversation.MixedLanguageType.LanguageDetection'
      );

      if (order1) {
        result.push(order1[0]);
      }
      if (order2) {
        result.push(order2[0]);
      }
      if (order3) {
        result.push(order3[0]);
      }
      if (order4) {
        result.push(order4[0]);
      }
      if (order5) {
        result.push(order5[0]);
      }
      if (order6) {
        result.push(order6[0]);
      }
    }

    return result;
  }

  onDynamicDataMaskingEnabledChanged(eventArgs) {
    if (eventArgs.target.checked) {
      this.settingsForm.get('dynamicDataMaskingUrl').setValidators(Validators.required);
      this.settingsForm.get('dynamicDataMaskingUrl').setValue('');
      this.settingsForm.get('dynamicDataMaskingUrl').updateValueAndValidity();
    } else {
      this.settingsForm.get('dynamicDataMaskingUrl').clearValidators();
    }
  }
}
