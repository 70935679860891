import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { StateReset } from 'ngxs-reset-plugin';
import { environment } from '../../../environments/environment';
import { ConversationModuleState } from 'src/core/states/conversation/conversation-module.state';
import { CALayoutState } from 'src/core/states/layout/ca-layout.state';
import { ConversationMarkState } from 'src/core/states/conversation/conversation-mark.state';
import { DashboardModuleState } from 'src/core/states/dashboard/dashboard-module.state';
import { AdministrationModuleState } from 'src/core/states/administration/administration-module.state';
import { TrendAnalysisState } from 'src/core/states/analysis/trend-analysis.state';
import { StatisticComparisonState } from 'src/core/states/analysis/statistic-comparison.state';
import { FilterPanelState } from 'src/ca-shared/filter-panel/filter-panel.module';
import { EnumState } from 'src/core/states/enum/enum.state';
import { UserStateSettingsService } from './user-state-settings.service';
import { combineLatest, Observable } from 'rxjs';
import { EvaluationResultState } from 'src/core/states/quality/evaluation-result.state';
import { NonFcrState } from 'src/core/states/analysis/non-fcr.state';
import { QualityDashboardModuleState } from 'src/core/states/quality/quality-dashboard-module.state';
import { AgentPerformanceModuleState } from 'src/core/states/quality/agent-performance-module.state';
import { OAuthService } from 'angular-oauth2-oidc';
import { EvaluatorPerformanceModuleState } from 'src/core/states/quality/evaluator-performance-module.state';
import { SupervisorDashboardState } from 'src/core/states/real-time/supervisor-dashboard.state';

@Injectable({
  providedIn: 'root',
})
export class StateVersionService {
  private readonly _storageKey = 'ca-state-version';

  constructor(
    private store: Store,
    private userStateSettingsService: UserStateSettingsService,
    private oAuthService: OAuthService
  ) {}

  check(): void {
    if (!this.oAuthService.hasValidAccessToken()) {
      return;
    }

    const runningVersion = localStorage.getItem(this._storageKey);
    const environmentVersion = (environment.application as any).stateVersion;

    if (environmentVersion && runningVersion) {
      if (environmentVersion !== runningVersion) {
        localStorage.setItem(this._storageKey, environmentVersion);

        this.store.dispatch(
          new StateReset(
            ConversationModuleState,
            CALayoutState,
            ConversationMarkState,
            DashboardModuleState,
            AdministrationModuleState,
            TrendAnalysisState,
            EvaluationResultState,
            NonFcrState,
            StatisticComparisonState,
            FilterPanelState,
            EnumState,
            QualityDashboardModuleState,
            AgentPerformanceModuleState,
            EvaluatorPerformanceModuleState,
            SupervisorDashboardState
          )
        );
      }
    }
  }

  resetAllStates(): Observable<any> {
    let resetUserSettingsRequest = this.userStateSettingsService.resetUserStateSettings();
    let storeDispatchRequest = this.store.dispatch(
      new StateReset(
        ConversationModuleState,
        CALayoutState,
        ConversationMarkState,
        DashboardModuleState,
        AdministrationModuleState,
        TrendAnalysisState,
        EvaluationResultState,
        NonFcrState,
        StatisticComparisonState,
        FilterPanelState,
        EnumState,
        QualityDashboardModuleState,
        AgentPerformanceModuleState,
        EvaluatorPerformanceModuleState,
        SupervisorDashboardState
      )
    );
    let combinedRequest = combineLatest([resetUserSettingsRequest, storeDispatchRequest]);
    return combinedRequest;
  }
}
