import { AuthService, LocalizationService } from '@abp/ng.core';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OAuthService } from 'angular-oauth2-oidc';
import { GlobalSettingsService } from '../../../core/services/settings/global-settings.service';

@Component({
  selector: 'ca-idle-timeout-modal',
  templateUrl: './idle-timeout-modal.component.html',
  styleUrls: ['./idle-timeout-modal.component.scss'],
})
export class IdleTimeoutModalComponent implements OnInit {
  @ViewChild('idleTimeoutModal') idleTimeoutModal: any;
  @ViewChild('logoutFrame', { static: true, read: ElementRef })
  logoutFrame: ElementRef;

  idleTimeoutMilliSecond: number;
  modalTimeoutMilliSecond = 60000;
  idleTimeoutCheckMilliSecond = 1000;
  remainingSecondText: string;
  get isLoggedOut(): boolean {
    let lastActivityTimeObj = localStorage.getItem('lastActivityTime');
    if (!this.hasToken) {
      return true;
    } else if (lastActivityTimeObj) {
      return false;
    }
  }

  get hasToken(): boolean {
    if (localStorage.getItem('access_token') !== null) {
      return true;
    } else {
      return false;
    }
  }

  get remainingTimeoutSecond(): number {
    let lastActivityTimeObj = localStorage.getItem('lastActivityTime');
    let dateDiff = new Date().getTime() - new Date(lastActivityTimeObj).getTime();
    let seconds = Math.round((this.idleTimeoutMilliSecond - dateDiff) / 1000);
    if (seconds > 0) {
      return seconds;
    } else {
      return 0;
    }
  }

  get idleWarningMilliSecond() {
    return this.idleTimeoutMilliSecond - this.modalTimeoutMilliSecond;
  }

  get sessionExirationInfoText() {
    if (!this.remainingSecondText) {
      this.remainingSecondText = (this.modalTimeoutMilliSecond / 1000).toString();
    }
    return this.localizationService.instant(
      '::SessionExpirationWarningMessage',
      this.remainingSecondText
    );
  }

  constructor(
    private modalService: NgbModal,
    private localizationService: LocalizationService,
    private oauthService: OAuthService,
    private authService: AuthService,
    private http: HttpClient,
    private renderer: Renderer2,
    private globalSettingsService: GlobalSettingsService
  ) {
    this.idleTimeoutMilliSecond =
      this.globalSettingsService.idleTimeoutMinute * 1000 * 60 + this.modalTimeoutMilliSecond;
  }

  openTimeoutModal() {
    this.modalService.open(this.idleTimeoutModal, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      size: 'md',
    });
  }

  setCountDown(timeoutComponent) {
    timeoutComponent.remainingSecondText = timeoutComponent.remainingTimeoutSecond;

    // Control For Browser tabs if stay clicked.
    if (timeoutComponent.remainingTimeoutSecond * 1000 > timeoutComponent.modalTimeoutMilliSecond) {
      timeoutComponent.onStayLoginClick();
    } // Control for timeout.
    else if (timeoutComponent.remainingTimeoutSecond === 0) {
      timeoutComponent.logOutWithoutRedirect(timeoutComponent);
    } // Continue to the status control.
    else {
      setTimeout(timeoutComponent.setCountDown, 1000, timeoutComponent);
    }
  }

  checkLoginStatus(timeoutComponent) {
    if (timeoutComponent.modalService.hasOpenModals()) {
      if (timeoutComponent.hasToken) {
        timeoutComponent.modalService.dismissAll();
      } else {
        setTimeout(timeoutComponent.checkLoginStatus, 1000, timeoutComponent);
      }
    }
  }

  logOutWithoutRedirect(timeoutComponent) {
    timeoutComponent.oauthService.logOut(true);
    timeoutComponent.logoutFrame.nativeElement.src = timeoutComponent.oauthService.loginUrl
      .toLowerCase()
      .replace('connect/authorize', 'account/logout');
    localStorage.removeItem('lastActivityTime');
    timeoutComponent.checkLoginStatus(timeoutComponent);
  }

  controlIdleStatus(timeoutComponent) {
    let lastActivityTimeObj = localStorage.getItem('lastActivityTime');
    let lastActivityTime = new Date(lastActivityTimeObj);
    let idleTime = new Date().getTime() - lastActivityTime.getTime();

    if (idleTime >= timeoutComponent.idleWarningMilliSecond) {
      timeoutComponent.openTimeoutModal();
      timeoutComponent.setCountDown(timeoutComponent);
    } else {
      setTimeout(
        timeoutComponent.controlIdleStatus,
        timeoutComponent.idleTimeoutCheckMilliSecond,
        timeoutComponent
      );
    }
  }

  ngOnInit(): void {
    if (this.globalSettingsService.idleTimeoutEnabled && this.hasToken) {
      if (localStorage.getItem('lastActivityTime') === null) {
        this.updateLastActionTime();
      }

      this.initializeIdleTimeout();
      this.initializeEventListeners();
    }
  }

  initializeEventListeners() {
    let me = this;
    let events = ['mousemove', 'keydown', 'click'];
    events.forEach(event => {
      this.renderer.listen('document', event, event => {
        this.documentEventHandler(me);
      });
    });
  }

  initializeIdleTimeout() {
    setTimeout(this.controlIdleStatus, this.idleTimeoutCheckMilliSecond, this);
  }

  updateLastActionTime() {
    localStorage.setItem('lastActivityTime', new Date().toString());
  }

  onNavigateToLogin() {
    localStorage.removeItem('lastActivityTime');
    this.oauthService.redirectUri = window.location.href;
    this.oauthService['persistRedirectUri'] = true;
    this.authService.navigateToLogin();
  }

  onLogoutClick() {
    this.modalService.dismissAll();
    localStorage.removeItem('lastActivityTime');
    this.logoutWithRedirect();
  }

  onStayLoginClick() {
    this.modalService.dismissAll();
    this.initializeIdleTimeout();
    this.updateLastActionTime();
  }

  documentEventHandler(idleTimeoutComponent) {
    if (!idleTimeoutComponent.modalService.hasOpenModals() && idleTimeoutComponent.hasToken) {
      idleTimeoutComponent.updateLastActionTime();
    }
  }

  private logoutWithRedirect() {
    this.oauthService.logOut();
  }
}
