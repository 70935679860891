import { FormBuilder } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FilterPanelEditorComponent } from 'src/ca-shared/filter-panel/models/filter-panel-editor.component';
import { FilterPanelEditorConfigurationModel } from 'src/ca-shared/filter-panel/models/filter-panel-editor-configuration.model';
import { CategorySelectionConfigurationModel } from '../models/category-selection-configuration.model';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap/popover/popover';
import { CategoryDto } from 'src/core/models/query/category.dto';
import { Operators } from 'src/core/models/request/operator.enum';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
import { SelectorComponent } from 'src/ca-shared/selector/selector.module';
import { ColorUtils } from 'src/core/helpers/color-utils';
import { combineLatest, Subscription } from 'rxjs';
import { CrudService } from 'src/core/services/crud/crud.service';
import { QueryService } from 'src/core/services/query/query.service';
import { QueryItemsCategoriesTupleDto } from 'src/core/models/query/query-items-categories-tuple.dto';
import { QueryDto } from 'src/core/models/query/query.dto';
import { QueryItemDto } from 'src/core/models/query/query-item.dto';
import { TopicService } from 'src/core/services/ai-topic/topic.service';
import { TopicClusterKeywordDto } from 'src/core/models/conversation/ai-topic/topic-cluster-keyword.dto';
import { CategoryType } from 'src/core/models/enum/category-type.enum';
import { SorterItemDto } from 'src/core/models/request/sorter-item.dto';
import { QueryTupleDto } from 'src/core/models/query/query-tuple.dto';

@Component({
  selector: 'ca-category-selection-panel',
  templateUrl: './category-selection-panel.component.html',
  styleUrls: ['./category-selection-panel.component.scss'],
})
export class CategorySelectionPanelComponent extends FilterPanelEditorComponent implements OnInit {
  actualConfig: CategorySelectionConfigurationModel;
  _value: CategoryDto[];
  categoryDropdownOptions: any;
  isQueryPreviewLoading: boolean;
  previewQueryId: number;
  queryPreviewDataCache = new Map<number, QueryTupleDto>();
  queryPreviewDataSubscription: Subscription;
  isTopicPreviewLoading: boolean;
  previewTopicId: number;
  topicPreviewDataCache = new Map<number, TopicClusterKeywordDto[]>();

  @ViewChild(SelectorComponent, { read: SelectorComponent }) categorySelector: SelectorComponent;

  get categoryType(): typeof CategoryType {
    return CategoryType;
  }

  set config(value: FilterPanelEditorConfigurationModel) {
    this.actualConfig = value as CategorySelectionConfigurationModel;
  }

  get config(): FilterPanelEditorConfigurationModel {
    return this.actualConfig;
  }

  set value(value: any) {
    this.editorForm.patchValue({
      editor: value,
    });
  }
  get value(): any {
    return this._value;
  }

  private innerLoad() {
    const filters: FilterItemDto[] = [];
    if (this.extraData.conversationType && this.extraData.conversationType.length > 0) {
      filters.push({
        field: 'conversationType',
        operator: this.operators.In,
        value: this.extraData.conversationType,
      });
    }
    filters.push({
      field: 'isActive',
      operator: this.operators.Equals,
      value: true,
    });

    filters.push({
      field: 'isActive',
      operator: this.operators.Equals,
      value: true,
    });

    filters.push({
      field: 'notMerged',
      operator: this.operators.Equals,
      value: true,
    });

    if (this.actualConfig.onlyInculeInReports === true) {
      filters.push({
        field: 'isCategory',
        operator: this.operators.Equals,
        value: true,
      });
    }

    if (this.actualConfig.onlyNonFcrGroupByCategory === true) {
      filters.push({
        field: 'onlyNonFcrGroupByCategory',
        operator: this.operators.Equals,
        value: true,
      });
    }

    if (this.actualConfig.includeUncategorized) {
      filters.push({
        field: 'includeUncategorized',
        operator: this.operators.Equals,
        value: true,
      });
    }

    this.categorySelector.setFilters(filters);
    this.categorySelector.refresh();
  }

  autoSelectFirstItem(): void {}

  reload(): void {
    this.innerLoad();
  }

  reset(): void {
    this.categorySelector.currentPage = 1;
  }

  init() {
    super.init();

    if (this.actualConfig.autoLoad) {
      this.innerLoad();
    }
  }

  toggleQueryPreviewPopover(popover: NgbPopover, category: CategoryDto, event: any): boolean {
    this.previewQueryId = category.queryId;

    if (popover.isOpen()) {
      popover.close();
      this.previewQueryId = 0;
    } else {
      const data = this.queryPreviewDataCache.get(category.queryId);
      if (data == null) {
        this.isQueryPreviewLoading = true;
        const categoriesRequest = this.queryService.getQueryCategories();
        const queryItemsRequest = this.service.getById<QueryItemDto[]>(
          QueryDto,
          category.queryId,
          'items'
        );

        const queryRequest = this.service.getById<QueryDto>(QueryDto, category.queryId);

        this.queryPreviewDataSubscription = combineLatest([
          categoriesRequest,
          queryItemsRequest,
          queryRequest,
        ]).subscribe(([categories, queryItems, query]) => {
          const previewData: QueryTupleDto = {
            queryBuilderCategoryData: categories.items,
            queryItems: queryItems,
            query: query,
          };

          this.queryPreviewDataCache.set(category.queryId, previewData);
          this.isQueryPreviewLoading = false;
          popover.open({
            query: query,
            queryItems: queryItems,
            categories: categories.items,
          });
        });
      } else {
        popover.open({
          query: data.query,
          queryItems: data.queryItems,
          categories: data.queryBuilderCategoryData,
        });
      }
    }

    event.stopPropagation();
    return false;
  }

  toggleTopicPreviewPopover(popover: NgbPopover, category: CategoryDto, event: any): boolean {
    this.previewTopicId = category.topicId;

    if (popover.isOpen()) {
      popover.close();
      this.previewTopicId = 0;
    } else {
      const data = this.topicPreviewDataCache.get(category.topicId);
      if (data == null) {
        this.isTopicPreviewLoading = true;

        this.topicService.getTopicKeywordsById(category.topicId).subscribe(data => {
          this.topicPreviewDataCache.set(category.topicId, data);
          this.isTopicPreviewLoading = false;
          popover.open({ phrases: data.map(k => k.token) });
        });
      } else {
        popover.open({ phrases: data.map(k => k.token) });
      }
    }

    event.stopPropagation();
    return false;
  }

  constructor(
    private operators: Operators,
    public colorUtils: ColorUtils,
    private service: CrudService,
    private queryService: QueryService,
    private topicService: TopicService,
    protected fb: FormBuilder
  ) {
    super(fb);

    this.editorForm.valueChanges.subscribe(val => {
      this._value = val.editor;
      this.onChange();
    });
  }

  ngOnInit(): void {
    let sorters: SorterItemDto[] = [];

    sorters.push({
      field: 'Name',
      direction: 'ASC',
    });

    this.categoryDropdownOptions = {
      pageSize: 9999,
      queryOperator: this.operators.Contains,
      queryField: 'quickSearchTerm',
      url: 'api/app/category/selector-data',
      currentPage: 1,
      showSelections: this.actualConfig.multiple,
      showClearSelections: this.actualConfig.multiple,
      showClearSelectionLink: !this.actualConfig.multiple,
      selectorPosition: 'Top',
      sorters: sorters,
    };
  }
}
