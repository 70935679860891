import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DropdownTreeModule } from './../dropdown-tree/dropdown-tree.module';
import { ValidationMessageService } from './../../core/services/validation/validation-message.service';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { SelectorModule } from './../selector/selector.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPanelComponent } from './filter-panel/filter-panel.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { DatetimePickerModule } from '../datetime-picker/datetime-picker.module';
import { DateSelectionPanelComponent } from './children/date-selection/date-selection-panel/date-selection-panel.component';
import { FilterPanelEditorFactoryComponent } from './filter-panel-editor-factory/filter-panel-editor-factory.component';
import { GenericLookupSelectionPanelComponent } from './children/generic-lookup-selection/generic-lookup-selection-panel/generic-lookup-selection-panel.component';
import { TextEditorComponent } from './children/text/text-editor/text-editor.component';
import { QuerySelectionPanelComponent } from './children/query-selection/query-selection-panel/query-selection-panel.component';
import { CategorySelectionPanelComponent } from './children/category-selection/category-selection-panel/category-selection-panel.component';
import { DepartmentSelectionPanelComponent } from './children/department-selection/department-selection-panel/department-selection-panel.component';
import { UserSelectionPanelComponent } from './children/user-selection/user-selection-panel/user-selection-panel.component';
import { RoleSelectionPanelComponent } from './children/role-selection/role-selection-panel/role-selection-panel.component';
import { ConversationSideSelectionPanelComponent } from './children/conversation-side-selection/conversation-side-selection-panel/conversation-side-selection-panel.component';
import { CaSharedModule } from '../ca-shared.module';
import { AssignmentSelectionPanelComponent } from './children/assignment-selection/assignment-selection-panel/assignment-selection-panel.component';
import { FormSelectionPanelComponent } from './children/form-selection/form-selection-panel/form-selection-panel.component';
import { TopicSelectionPanelComponent } from './children/topic-selection/topic-selection-panel/topic-selection-panel.component';
import { AutomaticAssignmentSelectionPanelComponent } from './children/automatic-assignment-selection/automatic-assignment-selection-panel/automatic-assignment-selection-panel.component';
import { TagSelectionPanelComponent } from './children/tag-selection/tag-selection-panel/tag-selection-panel.component';
import { AttachedDataSelectionPanelComponent } from './children/attached-data-selection/attached-data-panel/attached-data-selection-panel.component';
import { NumberEditorComponent } from './children/number-editor/number-editor.component';
import { QuickSearchWithSideSelectionPanelComponent } from './children/quick-search-with-side-selection-panel/quick-search-with-side-selection-panel.component';
import { BooleanSelectionPanelComponent } from './children/boolean-selection/boolean-selection-panel/boolean-selection-panel.component';
import { NumberEditorWithOperatorComponent } from './children/number-editor-with-operator/number-editor-with-operator.component';
import { ScenarioSelectionPanelComponent } from './children/scenario-selection/scenario-selection-panel/scenario-selection-panel.component';
import { FormVersionSelectionPanelComponent } from './children/form-version-selection/form-version-selection-panel/form-version-selection-panel.component';
import { QuestionSelectionPanelComponent } from './children/question-selection/question-selection-panel/question-selection-panel.component';
import { FormSectionSelectionPanelComponent } from './children/form-section-selection/form-section-selection-panel/form-section-selection-panel.component';
import { LastLoginDateSelectionPanelComponent } from './children/last-login-date-selection/last-login-date-selection-panel/last-login-date-selection-panel.component';

export * from './filter-panel/filter-panel.component';
export * from './models/filter-panel-configuration.model';

export * from './children/date-selection/models/date-selection-configuration.model';

export * from './children/generic-lookup-selection/models/generic-lookup-selection-configuration.model';
export * from './children/generic-lookup-selection/models/generic-lookup-selection-type.enum';

export * from './children/text/models/text-editor-configuration.model';

export * from './children/query-selection/models/query-selection-configuration.model';

export * from './children/category-selection/models/category-selection-configuration.model';

export * from './children/department-selection/models/department-selection-configuration.model';
export * from './children/department-selection/models/department-selection-type.enum';

export * from './children/user-selection/models/user-selection-configuration.model';
export * from './children/user-selection/models/user-selection-type.enum';

export * from './children/scenario-selection/models/scenario-selection-configuration.model';
export * from './children/form-version-selection/models/form-version-selection-configuration.model';
export * from './children/form-selection/models/form-selection-type.enum';
export * from './children/form-selection/models/form-selection-configuration.model';
export * from './children/question-selection/models/question-selection-configuration-model';
export * from './children/form-section-selection/models/form-section-selection-configuration-model';
export * from './states/filter-panel.state';

@NgModule({
  declarations: [
    FilterPanelComponent,
    DateSelectionPanelComponent,
    FilterPanelEditorFactoryComponent,
    GenericLookupSelectionPanelComponent,
    TextEditorComponent,
    QuerySelectionPanelComponent,
    CategorySelectionPanelComponent,
    DepartmentSelectionPanelComponent,
    UserSelectionPanelComponent,
    RoleSelectionPanelComponent,
    ConversationSideSelectionPanelComponent,
    AssignmentSelectionPanelComponent,
    FormSelectionPanelComponent,
    TopicSelectionPanelComponent,
    AutomaticAssignmentSelectionPanelComponent,
    TagSelectionPanelComponent,
    AttachedDataSelectionPanelComponent,
    NumberEditorComponent,
    QuickSearchWithSideSelectionPanelComponent,
    BooleanSelectionPanelComponent,
    NumberEditorWithOperatorComponent,
    ScenarioSelectionPanelComponent,
    FormVersionSelectionPanelComponent,
    QuestionSelectionPanelComponent,
    FormSectionSelectionPanelComponent,
    LastLoginDateSelectionPanelComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    CaSharedModule,
    NgbModule,
    DatetimePickerModule,
    SelectorModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    DropdownTreeModule,
    MatSlideToggleModule,
  ],
  exports: [FilterPanelComponent, DateSelectionPanelComponent],
  providers: [
    {
      provide: ValidationMessageService,
      useClass: ValidationMessageService,
    },
  ],
})
export class FilterPanelModule { }
