import { Injectable } from "@angular/core";
import { QueryFieldDataType } from "../models/query/query-field-data-type.enum";

@Injectable({
    providedIn: 'root',
})
export class QueryConstants {
    static readonly RANGE_UNIT_SECONDS_CODE = 'Query.RangeUnit.Seconds';
    static readonly RANGE_OPENING_START_SECOND = 0;
    static readonly RANGE_OPENING_END_SECOND = 60;
    static readonly RANGE_CLOSING_START_SECOND = 60;
    static readonly RANGE_CLOSING_END_SECOND = 0;

    static readonly CATEGORY_ITEM_ID_ORDERED = 1;
    static readonly CATEGORY_ITEM_ID_ORDERED_INTERVAL = 2;
    static readonly CATEGORY_ITEM_ID_SIMPLE = 3;
    static readonly CATEGORY_ITEM_ID_SIMPLE_NOT = 4;
    static readonly CATEGORY_ITEM_ID_SIMPLE_OCCURENCE = 5;
    static readonly CATEGORY_ITEM_ID_SIMPLE_INTERVAL = 6;

    static readonly CATEGORY_ITEM_ID_NEAR = 8;
    static readonly CATEGORY_ITEM_ID_NEAR_INTERVAL = 11;

    get categoriesToHide(): number[] {
        let categories: number[] = [];
        // These data types are all handled by the new Simple Term Editor.
        categories.push(this.queryFieldDataType.SimpleTermWithNot);
        categories.push(this.queryFieldDataType.SimpleTermWithOccurence);
        categories.push(this.queryFieldDataType.SimpleTermWithRange);
        categories.push(this.queryFieldDataType.SimpleTermWithRangeAndOccurence);

        // This data type is  handled by the new Ordered Editor.
        categories.push(this.queryFieldDataType.OrderedGroupWithRange);

        // This data type is  handled by the new Near Editor.
        categories.push(this.queryFieldDataType.NearQueryWithRange);
        return categories;
    }

    constructor(public queryFieldDataType: QueryFieldDataType) {
    }
}