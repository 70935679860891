import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownTreeComponent } from './components/dropdown-tree/dropdown-tree.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { DropdownTreeNodeComponent } from './components/dropdown-tree-node/dropdown-tree-node.component';
import { FormsModule } from '@angular/forms';
import { DropdownTreeToolbarComponent } from './components/dropdown-tree-toolbar/dropdown-tree-toolbar.component';
import { MatDividerModule } from '@angular/material/divider';
import { LocalizationModule } from '@abp/ng.core';
import { IconsModule } from '../icons/icons.module';

export * from './models/dropdown-tree-node.model';

@NgModule({
  declarations: [
    DropdownTreeComponent,
    DropdownTreeNodeComponent,
    DropdownTreeNodeComponent,
    DropdownTreeToolbarComponent,
  ],
  imports: [
    CommonModule,
    NgbDropdownModule,
    FormsModule,
    MatDividerModule,
    LocalizationModule,
    IconsModule,
  ],
  exports: [DropdownTreeComponent],
})
export class DropdownTreeModule {}
