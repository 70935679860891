import { ConfigStateService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class GlobalSettingsService {
  constructor(private config: ConfigStateService, private http: HttpClient) { }

  public get systemStartDateTicks(): number {
    return Number(this.config.getSetting('Global.SystemStartDate'));
  }

  public get systemStartDateMillis(): number {
    //TO-DO: there should be more effective solution for the static calculations.
    return (this.systemStartDateTicks - 621355968000000000) / 10000;
  }

  public get systemStartDate(): Date {
    return new Date(this.systemStartDateMillis);
  }

  public get systemEndDate(): Date {
    const date = new Date();
    return new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59);
  }

  public get mixedLanguageType(): string {
    return this.config.getSetting('Global.MixedLanguageType');
  }

  public get systemLanguageCode(): string {
    return this.config.getSetting('Global.SystemLanguageCode');
  }

  public get otherLangCodes(): string {
    return this.config.getSetting('Global.OtherLanguageCode');
  }

  public get inboundAgentChannel(): string {
    return this.config.getSetting('Global.InboundAgentChannel');
  }

  public get outboundAgentChannel(): string {
    return this.config.getSetting('Global.OutboundAgentChannel');
  }

  public get domainName(): string {
    return this.config.getSetting('Global.DomainName');
  }

  public get ldapCertificateName(): string {
    return this.config.getSetting('Global.LdapCertificateName');
  }

  public get userFeedbackReceiverEmail(): string {
    return this.config.getSetting('Global.UserFeedbackReceiverEmail');
  }

  public get maximumAnalysisTime(): number {
    return Number(this.config.getSetting('Global.MaximumAnalysisTime'));
  }

  public get systemTimeZone(): string {
    return this.config.getSetting('Global.SystemTimeZone');
  }

  public get systemDateFormat(): string {
    return this.config.getSetting('Global.SystemDateFormat');
  }

  public get idleTimeoutEnabled(): boolean {
    return this.config.getSetting('Global.IdleTimeoutEnabled').toLowerCase() === 'true' ? true : false;
  }

  public get idleTimeoutMinute(): number {
    return Number(this.config.getSetting('Global.IdleTimeoutMinute'));
  }

  public get autoPlayEnabled(): boolean {
    return this.config.getSetting('Global.AutoPlayEnabled').toLowerCase() === 'true' ? true : false;
  }

  public get corsOriginForEmbeddedAccess(): string {
    return this.config.getSetting('Auth.CorsOriginForEmbeddedAccess');
  }

  public get dynamicDataMaskingEnabled(): boolean {
    return this.config.getSetting('Global.DynamicDataMaskingEnabled').toLowerCase() === 'true' ? true : false;
  }

  public get dynamicDataMaskingUrl(): string {
    return this.config.getSetting('Global.DynamicDataMaskingUrl');
  }

  sendTestMail(email: string): Observable<boolean> {
    const url = this.getApiUrl() + '/api/app/test/send-email/' + email;

    return this.http.get(url) as Observable<boolean>;
  }

  systemStartDateTicksForTenant(tenantId: string): Observable<string> {
    const url =
      this.getApiUrl() + `/api/app/configuration-settings/` + tenantId + `/Global.SystemStartDate`;

    return this.http.get(url) as Observable<string>;
  }

  convertTicksToMillis(ticks: number): number {
    return (ticks - 621355968000000000) / 10000;
  }

  convertTicksStringToMillis(ticks: string): number {
    return this.convertTicksToMillis(Number(ticks));
  }

  generatePredefinedItems(languageCodes: string): Observable<string> {
    const url =
      this.getApiUrl() + '/api/app/global/generate-predefined-items/' + languageCodes;

    return this.http.get(url, { responseType: 'text' as 'json' }) as Observable<string>;
  }

  private getApiUrl(): string {
    const apiBase = environment.apis.default.url;

    return apiBase;
  }
}
