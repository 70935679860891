<ng-template #itemTemplate let-item="item">
    <div class="selection-panel-name">
      <div class="line-ellipsis">{{ item.text }}</div>
    </div>
  </ng-template>
  
  <ng-template #selectionTemplate let-item="item">
    <div class="filter-name">{{ item.text }}</div>
  </ng-template>
  
  <ng-container [formGroup]="editorForm">
    <ca-selector
      #formVersionQuestionSelector
      formControlName="editor"
      [url]="questionSelectorOptions.url"
      [filters]="questionSelectorOptions.filters"
      [itemTemplate]="itemTemplate"
      [selectionTemplate]="selectionTemplate"
      [queryOperator]="questionSelectorOptions.queryOperator"
      [queryField]="questionSelectorOptions.queryField"
      [multiple]="false"
      [emptyText]="questionSelectorOptions.emptyText"
      [showSelections]="questionSelectorOptions.showSelections"
      [showClearSelections]="questionSelectorOptions.showClearSelections"
      [showClearSelectionLink]="questionSelectorOptions.showClearSelectionLink"
      [selectorPosition]="questionSelectorOptions.selectorPosition"
      [styles]="{ width: '100%', 'border-width': '0px' }"
      [pageSize]="questionSelectorOptions.pageSize"
      [currentPage]="questionSelectorOptions.currentPage"
     ></ca-selector>
  </ng-container>