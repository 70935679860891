<ng-template
  #previewQueryPopContent
  let-queryItems="queryItems"
  let-categories="categories"
  let-query="query">
  <div *ngIf="!query.lastQueryVersionIndexed">
    {{ 'Query::IndexingContinuesMessage' | abpLocalization }}
  </div>
  <ca-query-detail-builder
    #builder
    [isReadonly]="true"
    (abpInit)="builder.loadQueryItems(queryItems, categories, query)"></ca-query-detail-builder>
</ng-template>

<ng-template #previewTopicPopContent let-phrases="phrases">
  <div>{{ 'Category::CategoryPreviewInfo' | abpLocalization }}:</div>
  <br />
  <div>{{ phrases.join(', ') }}</div>
</ng-template>

<ng-template #previewQueryPopTitle let-query="query">
  <div>
    {{ query.name }}
  </div>
</ng-template>

<ng-template #loaded>
  <mat-icon>info_outline</mat-icon>
</ng-template>

<ng-template #itemTemplate let-item="item">
  <div class="query-selection-panel-query-name">
    <div class="line-ellipsis">
      {{
        item.categoryType !== categoryType.Uncategorized
          ? item.name
          : ('Category::Uncategorized' | abpLocalization)
      }}
    </div>
    <div *ngIf="item.categoryType === categoryType.Query" class="query-selection-panel-info-button">
      <button
        type="button"
        mat-icon-button
        [ngStyle]="{
          color: !item.lastQueryVersionIndexed ? 'rgb(255, 157, 9)' : 'rgb(0, 0, 0)'
        }"
        style="vertical-align: top"
        #popover="ngbPopover"
        [ngbPopover]="previewQueryPopContent"
        [popoverTitle]="previewQueryPopTitle"
        popoverClass="query-preview-popover"
        placement="right auto"
        triggers="manual"
        container="body"
        [autoClose]="'outside'"
        (click)="toggleQueryPreviewPopover(popover, item, $event)">
        <div *ngIf="isQueryPreviewLoading && item.queryId === previewQueryId; else loaded">
          <mat-progress-spinner
            class="black-spinner"
            style="margin: 0 auto; padding: 0px"
            mode="indeterminate"
            diameter="16"></mat-progress-spinner>
        </div>
      </button>
    </div>
    <div *ngIf="item.categoryType === categoryType.Topic" class="query-selection-panel-info-button">
      <button
        type="button"
        mat-icon-button
        style="vertical-align: top"
        #popover="ngbPopover"
        [ngbPopover]="previewTopicPopContent"
        [popoverTitle]="item.name"
        popoverClass="query-preview-popover"
        placement="right auto"
        triggers="manual"
        container="body"
        [autoClose]="'outside'"
        (click)="toggleTopicPreviewPopover(popover, item, $event)">
        <div *ngIf="isTopicPreviewLoading && item.topicId === previewTopicId; else loaded">
          <mat-progress-spinner
            class="black-spinner"
            style="margin: 0 auto; padding: 0px"
            mode="indeterminate"
            diameter="16"></mat-progress-spinner>
        </div>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #selectionTemplate let-selection="selection">
  <span *ngIf="selection.categoryType == categoryType.Uncategorized">
    {{
      ('Category::Uncategorized' | abpLocalization).length < 16
        ? ('Category::Uncategorized' | abpLocalization)
        : ('Category::Uncategorized' | abpLocalization | slice : 0 : 12) + '...'
    }}</span
  >
  <span *ngIf="selection.categoryType != categoryType.Uncategorized">
    {{ selection.name.length < 16 ? selection.name : (selection.name | slice : 0 : 12) + '...' }}
  </span>
</ng-template>

<ng-container [formGroup]="editorForm">
  <ca-selector
    #categorySelector
    [url]="categoryDropdownOptions.url"
    [pageSize]="categoryDropdownOptions.pageSize"
    [itemTemplate]="itemTemplate"
    [selectionTemplate]="selectionTemplate"
    [queryOperator]="categoryDropdownOptions.queryOperator"
    [queryField]="categoryDropdownOptions.queryField"
    [emptyText]="categoryDropdownOptions.emptyText"
    [showSelections]="categoryDropdownOptions.showSelections"
    [showClearSelections]="categoryDropdownOptions.showClearSelections"
    [paginationDisabled]="true"
    [selectorPosition]="categoryDropdownOptions.selectorPosition"
    [showClearSelectionLink]="categoryDropdownOptions.showClearSelectionLink"
    [sorters]="categoryDropdownOptions.sorters"
    [styles]="{ width: '100%', 'border-width': '0px', height: '170px' }"
    [idProperty]="'name'"
    [multiple]="actualConfig.multiple"
    [maxSelectionCount]="actualConfig.maxSelectionCount"
    formControlName="editor"></ca-selector>
</ng-container>
