import { Directive, ElementRef, OnInit } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Directive({
  selector: '[ngbTooltip][showTooltipIfTruncated]',
})
export class ShowTooltipIfTruncatedDirective implements OnInit {
  constructor(private ngbTooltip: NgbTooltip, private elementRef: ElementRef<HTMLElement>) {}

  public ngOnInit(): void {
    setTimeout(() => {
      const element = this.elementRef.nativeElement;
      var scrollWidth = element.scrollWidth;
      var clientWidth = element.clientWidth;
      if (element.style.display && element.style.display.includes('inline')) {
        const offsetParent = element.parentElement;
        scrollWidth = offsetParent.scrollWidth;
        clientWidth = offsetParent.clientWidth;
      }
      this.ngbTooltip.disableTooltip = scrollWidth <= clientWidth;
    });
  }
}
