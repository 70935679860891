import { LocalizationService } from '@abp/ng.core';
import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class FormatHelper {
  constructor(private localizationService: LocalizationService) {}
  convertMillisecondToTimeString(millisecond: number): string {
    const seconds = Math.floor(millisecond / 1000);
    const durationHours = Math.floor(seconds / 3600)
      .toString()
      .padStart(2, '0');
    const durationMins = Math.floor((seconds / 60) % 60)
      .toString()
      .padStart(2, '0');
    const durationSecs = Math.floor(seconds % 60)
      .toString()
      .padStart(2, '0');
    return durationHours + ':' + durationMins + ':' + durationSecs;
  }

  convertSecondToString(seconds: number): string {
    let value = '';
    const durationHours = Math.floor(seconds / 3600)
      .toString()
      .padStart(1, '0');
    const durationMins = Math.floor((seconds / 60) % 60)
      .toString()
      .padStart(1, '0');
    const durationSecs = Math.floor(seconds % 60)
      .toString()
      .padStart(1, '0');

    if (durationHours !== '0') {
      value += durationHours + ' ' + this.localizationService.instant('::HourShort');
    }

    if (durationMins !== '0') {
      value += ' ' + durationMins + ' ' + this.localizationService.instant('::MinuteShort');
    }

    if (durationSecs !== '0') {
      value += ' ' + durationSecs + ' ' + this.localizationService.instant('::SecondsShort');
    }

    return value === '' ? '0' : value;
  }

  convertTimerSecondToShortString(seconds: number): string {
    let value = '';

    let durationMins = Math.floor((seconds / 60) % 60)
      .toString()
      .padStart(1, '0');

    let durationSecs = Math.floor((seconds % 60) / 6)
      .toString()
      .padStart(1, '0');

    value = durationMins + '.' + durationSecs;

    return value;
  }

  convertTimerSecondToString(seconds: number): string {
    let value = '';

    let durationMins = Math.floor((seconds / 60) % 60)
      .toString()
      .padStart(1, '0');
    let durationSecs = Math.floor(seconds % 60)
      .toString()
      .padStart(1, '0');

    if (Number.parseInt(durationMins) < 10) {
      durationMins = '0' + durationMins;
    }

    if (Number.parseInt(durationSecs) < 10) {
      durationSecs = '0' + durationSecs;
    }

    value = durationMins + ':' + durationSecs;

    return value;
  }

  convertStringToTimerSecond(time: string): number | null {
    if (!time) {
      return null;
    }

    const segments = time.split(':');
    const minutes = Number(segments[0]);
    const seconds = Number(segments[1]);

    const totalSeconds = minutes * 60 + seconds;

    return totalSeconds;
  }
}
