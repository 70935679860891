<ng-template #dropdownUserTemplate let-item="item">
  <span>{{ item.fullName }}</span>
</ng-template>

<ng-template #dropdownExceptUserTemplate let-item="item">
  {{ '::AllExcept' | abpLocalization : item.fullName }}
</ng-template>

<ng-template #dropdownDepartmentTemplate let-item="item">
  <span>{{ item.name }}</span>
</ng-template>

<ng-template #dropdownDepartmentExceptTemplate let-item="item">
  {{ '::AllExcept' | abpLocalization : item.name }}
</ng-template>

<form
  id="userGroupForm"
  [formGroup]="userGroupForm"
  (ngSubmit)="onSubmitForm()"
  #formDirective="ngForm"
  style="height: 100%">
  <div class="form-panel-wrapper">
    <div class="row entry-row submenu-row top-toolbar-row">
      <div class="col p-0">
        <div class="text-lg-right pt-2 ca-toolbar" id="AbpContentToolbar">
          <div class="ca-toolbar-west-section">
            <div class="toolbar-item first">
              <span>
                {{
                  userGroupDto?.id
                    ? userGroupDto.name
                    : ('UserGroup::NewUserGroup' | abpLocalization)
                }}
              </span>
            </div>
          </div>
          <div class="ca-toolbar-east-section">
            <div class="toolbar-item last">
              <button
                (click)="closePanel()"
                type="button"
                matTooltip="{{ '::ClosePanel' | abpLocalization }}"
                mat-icon-button>
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row form-row m-0">
      <div class="col p-2">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="name" class="indicates-required-field">
                {{ 'UserGroup::UserGroupName' | abpLocalization }}
              </label>
              <input
                type="text"
                class="form-control form-control-sm"
                id="Name"
                formControlName="name" />
            </div>
          </div>
        </div>
        <div class="row pt-2">
          <div class="col-5">
            <div class="form-group">
              <label for="userGroupUsers" class="indicates-required-field">
                {{ 'UserGroup::UserGroupUsersLabel' | abpLocalization }}
              </label>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <ca-dropdown-selector-wrapper
                [disabled]="false"
                [buttonString]="'::SelectUsers'"
                [buttonCls]="userErrorClass"
                [selectionTemplate]="dropdownUserTemplate"
                [selectionExceptTemplate]="dropdownExceptUserTemplate"
                [selections]="selectedUsers"
                [except]="isUsersExceptSelected"
                [exceptType]="'::AllExceptAgent'">
                <ca-user-auto-complete-selector
                  #userSelector
                  formControlName="userGroupUsers"
                  [userRoleSelection]="userRole"
                  [appendToBody]="false"
                  (ngModelChange)="checkSelectionErrors()">
                </ca-user-auto-complete-selector
              ></ca-dropdown-selector-wrapper>
            </div>
          </div>
        </div>
        <div class="row pt-2">
          <div class="col-5">
            <div class="form-group">
              <label for="userGroupDepartments" class="indicates-required-field">
                {{ 'UserGroup::UserGroupDepartmentsLabel' | abpLocalization }}
              </label>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <ca-dropdown-selector-wrapper
                [disabled]="false"
                [buttonString]="'::SelectDepartment'"
                [buttonCls]="departmentErrorClass"
                [selectionTemplate]="dropdownDepartmentTemplate"
                [selectionExceptTemplate]="dropdownDepartmentExceptTemplate"
                [selections]="selectedDepartments"
                [except]="isDepartmentsExceptSelected"
                [exceptType]="'::AllExceptDepartment'">
                <ca-department-selector
                  #departmentSelector
                  formControlName="userGroupDepartments"
                  (change)="checkSelectionErrors()">
                </ca-department-selector
              ></ca-dropdown-selector-wrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row submenu-row bottom-toolbar-row">
      <div class="col p-0">
        <div class="text-lg-right pt-2 ca-toolbar" id="AbpContentToolbar">
          <div class="ca-toolbar-east-section">
            <div class="toolbar-item">
              <button type="button" class="btn ca-btn ca-btn-neutral" (click)="closePanel()">
                {{ 'AbpUi::Cancel' | abpLocalization }}
              </button>
            </div>
            <div class="toolbar-item last">
              <div class="btn-group">
                <button
                  class="btn ca-btn ca-btn-primary-toolbar"
                  (click)="onClickSaveAndActivate()"
                  [disabled]="processing">
                  <ng-container *ngIf="!processing">
                    <i class="far fa-save"></i>
                    <span>{{ '::SaveAndActivate' | abpLocalization }}</span>
                  </ng-container>

                  <ng-container *ngIf="processing">
                    <i class="far fa-spinner"></i>
                    <span>{{ 'AbpUi::ProcessingWithThreeDot' | abpLocalization }}</span>
                  </ng-container>
                </button>
                <div
                  class="btn-group"
                  ngbDropdown
                  placement="top-end"
                  role="group"
                  container="body">
                  <button
                    type="button"
                    class="btn ca-btn ca-btn-primary-toolbar dropdown-toggle-split"
                    [disabled]="processing"
                    ngbDropdownToggle></button>
                  <div class="dropdown-menu" ngbDropdownMenu>
                    <button
                      (click)="onClickSaveAndDeactivate()"
                      ngbDropdownItem
                      class="dropdown-item-with-icon"
                      [disabled]="processing">
                      <i class="far fa-save"></i>
                      <span>{{ '::SaveAndDeactivate' | abpLocalization }}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
