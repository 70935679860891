export class Sections {
  static attachedData = 'attached-data';
  static departments = 'departments';
  static genericLookups = 'genericlookups';
  static users = 'users';
  static userLicenses = 'users-licenses';
  static queries = 'queries';
  static sources = 'sources';
  static topics = 'topics';
  static integrations = 'integrations';
  static scenario = 'scenario';
  static queryDetail = 'query-detail';
  static notifications = 'notifications';
  static trainings = 'trainings';
  static dashboard = 'dashboard';
  static statisticComparison = 'statistic-comparison';
  static trendAnalysis = 'trend-analysis';
  static ignoredWord = 'ignored-word';
  static nonFcrCalls = 'non-fcr';
  static genAiProject = 'gen-ai-project';
  static genAiProjectBuilder = 'gen-ai-project-builder';
  static form = 'form';
  static formDetail = 'form-detail';
  static reason = 'reason';
  static evaluationResult = 'evaluation-result';
  static automaticAssignment = 'automatic-assignment';
  static assignedByMe = 'assigned-by-me';
  static assignedToMe = 'assigned-to-me';
  static emailReport = 'email-report';
  static emailReportLog = 'email-report-log';
  static fennec = 'fennec';
  static tag = 'tag';
  static systemNotifications = 'system-notifications';
  static qualityDashboard = 'quality-dashboard';
  static profiles = 'profiles';
  static interactionMananegement = 'interaction-management';
  static formBuilder = 'form-builder';
  static qualityReport = 'quality-report';
  static supervisorDashboard = 'supervisor-dashboard';
}
