import { Operators } from 'src/core/models/request/operator.enum';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FilterItemDto } from 'src/core/models/request/filter-item.dto';
import { FilterPanelEditorComponent } from 'src/ca-shared/filter-panel/models/filter-panel-editor.component';
import { UserSelectionConfigurationModel } from 'src/ca-shared/filter-panel/filter-panel.module';
import { FormBuilder } from '@angular/forms';
import { FilterPanelEditorConfigurationModel } from 'src/ca-shared/filter-panel/models/filter-panel-editor-configuration.model';
import { UserAutoCompleteSelectorInputModel } from 'src/ca-shared/user-auto-complete-selector/models/user-auto-complete-selector-input.model';
import { UserAutoCompleteSelectorComponent } from 'src/ca-shared/user-auto-complete-selector/user-auto-complete-selector.component';
import { DepartmentFilterModeOption } from 'src/core/models/generic-lookup-type/department/department-filter-mode-option.glt';

@Component({
  selector: 'ca-user-selection-panel',
  templateUrl: './user-selection-panel.component.html',
  styleUrls: ['./user-selection-panel.component.scss'],
})
export class UserSelectionPanelComponent extends FilterPanelEditorComponent implements OnInit {
  @ViewChild('userSelector', {
    read: UserAutoCompleteSelectorComponent,
  })
  userSelector: UserAutoCompleteSelectorComponent;

  userFilters: FilterItemDto[] = [];
  actualConfig: UserSelectionConfigurationModel;
  _value: UserAutoCompleteSelectorInputModel;
  initialLoad = true;

  set config(value: FilterPanelEditorConfigurationModel) {
    this.actualConfig = value as UserSelectionConfigurationModel;
  }

  get config(): FilterPanelEditorConfigurationModel {
    return this.actualConfig;
  }

  set value(value: any) {
    this.replaceNullElement(value);
    this.editorForm.patchValue({
      editor: value,
    });
  }

  get value(): any {
    return this._value;
  }

  constructor(protected fb: FormBuilder, private operators: Operators) {
    super(fb);

    this.editorForm.valueChanges.subscribe(val => {
      this._value = val.editor;
      this.onChange();
    });
  }

  ngOnInit(): void { }

  init(): void {
    super.init();
    this.initialLoad = false;

    this.userFilters.push({
      field: 'isActive',
      operator: this.operators.Equals,
      value: true,
    });
    this.userSelector.setFilters(this.userFilters);

    if (this.actualConfig.autoLoad) {
      this.innerLoad();
    }
  }

  reload(): void {
    this.innerLoad();
  }

  reset(): void {
    this.innerLoad();
  }

  autoSelectFirstItem(): void { }

  private replaceNullElement(value: any) {
    if (
      this.actualConfig &&
      this.actualConfig.nullElement &&
      value &&
      value.selectedUsers &&
      value.selectedUsers.some(x => x.id === this.actualConfig.nullElement.id)
    ) {
      let index = value.selectedUsers.findIndex(x => x.id === this.actualConfig.nullElement.id);
      value.selectedUsers[index] = this.actualConfig.nullElement;
    }
  }

  private innerLoad() {
    const filters: FilterItemDto[] = [];

    this.userSelector.filters = this.userSelector.filters.filter(x => x.field !== 'departmentId');
    if (this.extraData.departmentIds && this.extraData.departmentIds.length > 0) {
      let departmentOperator = this.operators.In;
      if (
        Number(this.extraData.departmentFilterModeId) ==
        DepartmentFilterModeOption.AllDepartmentsExceptSelectedDeparment
      ) {
        departmentOperator = this.operators.NotContains;
      }

      filters.push({
        field: 'departmentId',
        operator: departmentOperator,
        value: this.extraData.departmentIds,
      });
    }
    this.userSelector.setFilters(filters);
  }
}
