import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map, first, mergeMap } from 'rxjs/operators';
import { GenericLookupDto } from 'src/core/models/generic-lookup/generic-lookup.dto';
import { GenericLookupTypeState } from 'src/core/states/generic-lookup-type/generic-lookup-type.state';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ConversationMarkChannel } from 'src/core/models/generic-lookup-type/conversation/conversation-mark-channel.glt';

@Component({
  selector: 'ca-conversation-mark-channel-selector',
  templateUrl: './conversation-mark-channel-selector.component.html',
  styleUrls: ['./conversation-mark-channel-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ConversationMarkChannelSelectorComponent),
      multi: true,
    },
  ],
})
export class ConversationMarkChannelSelectorComponent implements OnInit, ControlValueAccessor {
  conversationMarkChannel$: Observable<GenericLookupDto[]>;
  innerValue: number = 0;
  disabled: boolean = false;
  innerSelectedConversationMarkChannel: string;
  conversationMarkChannel = ConversationMarkChannel;

  onChange: any = () => { };
  onTouched: any = () => { };

  constructor(private store: Store) {
    this.conversationMarkChannel$ = this.store
      .select(GenericLookupTypeState.getGenericLookups)
      .pipe(map(filterFn => filterFn(ConversationMarkChannel)));
  }

  get selectedConversationMarkChannel(): string {
    return this.innerSelectedConversationMarkChannel;
  }

  get value(): number {
    return this.innerValue;
  }

  set value(newValue) {
    this.innerValue = newValue;
    this.conversationMarkChannel$.subscribe((channels: GenericLookupDto[]) => {
      this.innerSelectedConversationMarkChannel = channels.find(x => x.id == this.innerValue).code;
    });
  }

  getLookup(lookupCode: string): Observable<GenericLookupDto> {
    return this.conversationMarkChannel$.pipe(
      first(),
      mergeMap(x => x.filter(y => y.code == lookupCode))
    );
  }

  writeValue(value: number): void {
    if (value) {
      this.innerValue = value;
      this.conversationMarkChannel$.subscribe((channels: GenericLookupDto[]) => {
        this.innerSelectedConversationMarkChannel = channels.find(x => x.id == this.innerValue).code;
      });
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void { }

  onSelectionChange(newValue) {
    this.onChange(this.value);
    this.onTouched();
  }
}
