import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { HttpClient } from '@angular/common/http';
import { SpeechRecognitionResult } from 'src/core/models/speech-recognition/speech-recognition-result.model';
import * as Recorder from 'src/assets/js/recorderjs/recorder.js';
import * as vad from 'node_modules/voice-activity-detection/index.js';
import { EventEmitter, Injectable, OnDestroy, Output } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RecorderService implements OnDestroy {
  private readonly workerPath = './assets/js/recorderjs/recorderWorker.js';
  private stream: MediaStream;
  private audioContext: AudioContext;
  private source: MediaStreamAudioSourceNode;
  private recorder: Recorder;
  private silenceTimer;
  private silenceTimeout: number | null;
  private vadInstance: any;
  private defaultSilenceTimeout = Number.MAX_SAFE_INTEGER;
  private conversationId: number;
  private rowIndex: number;
  private referenceText: string;

  @Output()
  finishRecognition: EventEmitter<SpeechRecognitionResult> = new EventEmitter();

  @Output()
  exceedSilenceThreshold = new EventEmitter();

  constructor(
    private http: HttpClient,
    private configStateService: ConfigStateService,
    private localizationService: LocalizationService
  ) {}

  ngOnDestroy(): void {
    if (this.vadInstance) {
      this.vadInstance.destroy();
    }
  }

  initVAD(stream: MediaStream): void {
    const options = {
      onVoiceStart: this.onVoiceStart.bind(this),
      onVoiceStop: this.onVoiceStop.bind(this),
      onUpdate: val => {},
    };

    this.vadInstance = vad(this.audioContext, stream, options);
  }

  startRecording(
    conversationId: number,
    rowIndex: number,
    referenceText: string,
    silenceTimeout?: number | null
  ): void {
    this.silenceTimeout = silenceTimeout !== null ? silenceTimeout : this.defaultSilenceTimeout;
    this.conversationId = conversationId;
    this.rowIndex = rowIndex;
    this.referenceText = referenceText;

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(stream => {
        this.stream = stream;
        this.doRecord();
        this.initVAD(stream);
      })
      .catch(err => {
        alert(this.localizationService.instant('SpeechRecognition::RecognitionError'));
      });
  }

  stopRecording(): void {
    this.doStopRecording();
  }

  onVoiceStart(): void {
    clearTimeout(this.silenceTimer);
  }

  onVoiceStop(): void {
    this.silenceTimer = setTimeout(this.onExceedSilenceThreshold.bind(this), this.silenceTimeout);
  }

  onExceedSilenceThreshold(): void {
    clearTimeout(this.silenceTimer);
    this.doStopRecording();

    this.exceedSilenceThreshold.emit();
  }

  private recognize(data: Blob): void {
    const file: File = this.blobToFile(data, 'audio.wav');
    const formData = new FormData();

    formData.append('file', file);
    formData.append('referenceText', this.referenceText);

    this.http
      .post(
        'api/app/conversation/' + this.conversationId + '/' + this.rowIndex + '/recognize',
        formData
      )
      .subscribe((result: SpeechRecognitionResult) => {
        this.finishRecognition.emit(result);
      });
  }

  private doStopRecording() {
    this.recorder.stop();

    this.vadInstance.destroy();
    delete this.vadInstance;

    this.recorder.exportWAV((r: Blob) => {
      this.recognize(r);
    });

    this.stream.getTracks().forEach(s => s.stop());
  }

  private blobToFile = (theBlob: Blob, fileName: string): File => {
    var b: any = theBlob;
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;

    //Cast to a File() type
    return <File>theBlob;
  };

  private isSampleRateOptionSupported(alertResult) {
    const constraints = navigator.mediaDevices.getSupportedConstraints();
    const result = constraints.sampleRate ? true : false;

    if (alertResult) {
      alert('AudioContextOptions.sampleRate support status: ' + result);
    }
    return result;
  }

  private doRecord(): void {
    const strTargetSampleRate = this.configStateService.getSetting('SpeechRecognition.SampleRate');
    const targetSampleRate = !isNaN(parseInt(strTargetSampleRate, 10))
      ? parseInt(strTargetSampleRate, 10)
      : 8000;

    const enableUserImplementedResampling = !this.isSampleRateOptionSupported(false);

    this.audioContext = enableUserImplementedResampling
      ? new AudioContext()
      : new AudioContext({
          sampleRate: targetSampleRate,
        });

    this.source = this.audioContext.createMediaStreamSource(this.stream);

    const cfg = {
      workerPath: this.workerPath,
    };

    this.recorder = new Recorder(this.source, cfg);
    this.recorder.record();
  }
}
