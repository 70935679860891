<ca-clickable-link
  [innerHtml]="
    'QualityManagement::ConversationEvaluatedToastrAndNotificationMessage'
      | abpLocalization : payloadData?.conversationId
  "
  [targetLink]="['/quality/evaluation', payloadData?.evaluationMasterId]"
  [params]="{
    evaluationResultId: payloadData?.evaluationId,
    referrer: 'evaluation-result'
  }">
</ca-clickable-link>
