<ng-template #itemTemplate let-item="item">
  <div *ngIf="showPassives">
    <i
      [ngClass]="
        item.active ? 'fas fa-circle me-1 ms-1 active-item' : 'fas fa-circle me-1 ms-1 passive-item'
      "></i>
    <span class="filter-name"> {{ item.name }}</span>
  </div>
  <div *ngIf="!showPassives" class="filter-name">{{ item.name }}</div>
</ng-template>

<ng-template #selectionTemplate let-item="item">
  <div class="filter-name">{{ item.name }}</div>
</ng-template>

<ng-container [formGroup]="editorForm">
  <ca-selector
    #formSelector
    formControlName="editor"
    [url]="formSelectorOptions.url"
    [itemTemplate]="itemTemplate"
    [selectionTemplate]="selectionTemplate"
    [queryOperator]="formSelectorOptions.queryOperator"
    [queryField]="formSelectorOptions.queryField"
    [multiple]="multipleSelection"
    [emptyText]="formSelectorOptions.emptyText"
    [showSelections]="formSelectorOptions.showSelections"
    [showClearSelections]="formSelectorOptions.showClearSelections"
    [showClearSelectionLink]="formSelectorOptions.showClearSelectionLink"
    [showStatusCombobox]="true"
    [selectorPosition]="formSelectorOptions.selectorPosition"
    [styles]="{ width: '100%', 'border-width': '0px' }"
    [filters]="filters"
    [sorters]="sorters"
    [pageSize]="formSelectorOptions.pageSize"
    [currentPage]="formSelectorOptions.currentPage">
  </ca-selector>
</ng-container>
