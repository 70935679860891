<form [formGroup]="userDefinedCategorySettingsForm" (ngSubmit)="onSubmitCategorySettings()">
  <div class="row form-row">
    <h4>
      {{ 'Settings::IntervalCategorySettings' | abpLocalization }}
    </h4>
  </div>
  <div class="form-group pb-2">
    <input
      type="checkbox"
      id="seperateValueForChannelsEnabled"
      formControlName="seperateValueForChannelsEnabled"
      (change)="onSeperateValueChange($event)" />
    <label class="ps-1 fw-bold" for="seperateValueForChannelsEnabled">
      {{ 'Settings::SeperateValueForChannelsEnabled' | abpLocalization }}
    </label>
  </div>
  <div class="row form-row pb-2">
    <div class="row-container">
      <h5 class="mb-0" [hidden]="sameValueForChannels">
        {{ 'Settings::ForAgent' | abpLocalization }}
      </h5>
    </div>
    <div class="row-container">
      <div class="column-container col-3 first">
        <label for="agentBeginningWordCount" class="col-form-label pt-0 indicates-required-field">
          {{ ('Settings::OpeningInterval' | abpLocalization) + ' ' }}
        </label>
        <span class="row-container">
          <label class="col-form-label pt-0 pe-1 pb-0">
            {{ 'Settings::First' | abpLocalization }}
          </label>
          <input
            type="number"
            class="form-control form-control-sm"
            id="agentBeginningWordCount"
            formControlName="agentBeginningWordCount" />
          <label class="col-form-label pt-0 ps-1 pb-0">
            {{ 'Settings::Words' | abpLocalization }}
          </label>
        </span>
      </div>
      <div class="column-container col-3 second">
        <label for="agentEndingWordCount" class="col-form-label indicates-required-field">
          {{ ('Settings::ClosingInterval' | abpLocalization) + ' ' }}
        </label>
        <span class="row-container">
          <label class="col-form-label pt-0 pe-1 pb-0">
            {{ 'Settings::Last' | abpLocalization }}
          </label>
          <input
            type="number"
            class="form-control form-control-sm"
            id="agentEndingWordCount"
            formControlName="agentEndingWordCount" />
          <label class="col-form-label pt-0 ps-1 pb-0">
            {{ 'Settings::Words' | abpLocalization }}
          </label>
        </span>
      </div>
    </div>
    <div class="row-container" [hidden]="!sameValueForChannels">
      <small class="form-text text-muted">
        {{ 'Settings::SeperateValueForChannelsEnabledExplanation' | abpLocalization }}
      </small>
    </div>
  </div>
  <div class="row form-row" [hidden]="sameValueForChannels">
    <div class="row-container">
      <h5 class="mb-0">
        {{ 'Settings::ForCustomer' | abpLocalization }}
      </h5>
    </div>
    <div class="row-container">
      <div class="column-container col-3 first">
        <label
          for="customerBeginningWordCount"
          class="col-form-label pt-0 indicates-required-field">
          {{ ('Settings::OpeningInterval' | abpLocalization) + ' ' }}
        </label>
        <span class="row-container">
          <label class="col-form-label pt-0 pe-1 pb-0">
            {{ 'Settings::First' | abpLocalization }}
          </label>
          <input
            type="number"
            class="form-control form-control-sm"
            id="customerBeginningWordCount"
            formControlName="customerBeginningWordCount" />
          <label class="col-form-label pt-0 ps-1 pb-0">
            {{ 'Settings::Words' | abpLocalization }}
          </label>
        </span>
      </div>
      <div class="column-container col-3 second">
        <label for="customerEndingWordCount" class="col-form-label indicates-required-field">
          {{ ('Settings::ClosingInterval' | abpLocalization) + ' ' }}
        </label>
        <span class="row-container">
          <label class="col-form-label pt-0 pe-1 pb-0">
            {{ 'Settings::First' | abpLocalization }}
          </label>
          <input
            type="number"
            class="form-control form-control-sm"
            id="customerEndingWordCount"
            formControlName="customerEndingWordCount" />
          <label class="col-form-label pt-0 ps-1 pb-0">
            {{ 'Settings::Words' | abpLocalization }}
          </label>
        </span>
      </div>
    </div>
  </div>

  <button type="button" type="submit" class="btn btn-primary mt-3 mb-3">
    <i class="me-1 fas fa-save"></i>
    {{ 'AbpUi::Save' | abpLocalization }}
  </button>
</form>
