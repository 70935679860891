<div
  [style]="marginPositionWithValue"
  (dblclick)="onDblClick($event)"
  class="dropdown-tree-node-wrapper"
  [hidden]="data.hidden"
>
  <span
    class="expand-collapse-node-icon"
    (click)="onExpandIconClick($event)"
    [style.visibility]="data.leaf ? 'hidden' : 'visible'"
    ><i [ngClass]="data.expanded ? 'fas fa-angle-down' : 'fas fa-angle-right'"></i
  ></span>
  <label class="node-label" title="{{ data.disabled ? disabledItemMessage : '' }}">
    <span class="node-checkbox">
      <input
        type="checkbox"
        [(ngModel)]="data.selected"
        (change)="onSelectionChange($event)"
        value=""
        style="margin-top: 4px"
        [disabled]="disabled || data.disabled"
      />
    </span>
    <span
      class="node-text me-1 ms-1 {{
        hasSelectedChild && !data.selected ? 'font-weight-bold' : ''
      }}  "
    >
      <ng-container
        *ngTemplateOutlet="nodeTemplate; context: { payload: data.payload }"
      ></ng-container>
    </span>
  </label>
</div>

<ca-dropdown-tree-node
  [level]="level + 1"
  [data]="node"
  [hidden]="!data.expanded"
  [hasSelectedChild]="hasUnselectedChild(node)"
  [nodeTemplate]="nodeTemplate"
  [owner]="owner"
  [idProperty]="idProperty"
  [parentIdProperty]="parentIdProperty"
  [disabled]="disabled"
  [disabledItemMessage]="disabledItemMessage"
  *ngFor="let node of children"
></ca-dropdown-tree-node>
