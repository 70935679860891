import { Injectable } from '@angular/core';
import { ListRequestDto } from 'src/core/models/request/list-request.dto';
import { ListResponseDto } from 'src/core/models/request/list-response.dto';
import { Observable } from 'rxjs';
import { HttpParams, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DtoHasApi } from 'src/core/models/shared/dto-has-api.model';
import { HttpRequestOptions } from 'src/core/models/http/http-request-options.model';
import { SimpleRequestDto } from 'src/core/models/request/simple-request.dto';

@Injectable({
  providedIn: 'root',
})
export class CrudService {
  constructor(public http: HttpClient) {}

  get<TDto>(type: any, request: ListRequestDto | null): Observable<ListResponseDto<TDto>> {
    const apiUrl = this.getApiUrl(type);
    let params = request === null ? null : new HttpParams();

    if (request !== null) {
      params = params.append('filters', JSON.stringify(request.filters));
      params = params.append('sorters', JSON.stringify(request.sorters));
      params = params.append('skipCount', JSON.stringify(request.skipCount));
      params = params.append('maxResultCount', JSON.stringify(request.maxResultCount));
    }

    return this.http.get(apiUrl, {
      params,
    }) as Observable<ListResponseDto<TDto>>;
  }

  getSimple<T>(type: any, request: SimpleRequestDto | null, suffix?: string): Observable<T> {
    const apiUrl = this.getApiUrl(type, suffix);
    let params = request === null ? null : new HttpParams();

    if (request !== null) {
      params = params.append('request', JSON.stringify(request));
    }

    return this.http.get(apiUrl, {
      params,
    }) as Observable<T>;
  }

  getSubList<TDto>(
    type: any,
    request: ListRequestDto | null,
    id: number,
    suffix?: string
  ): Observable<ListResponseDto<TDto>> {
    const apiUrl = this.getApiUrl(type, id, suffix);
    let params = request === null ? null : new HttpParams();

    if (id !== null && request !== null) {
      params = params.append('id', JSON.stringify(id));
    }

    if (request !== null) {
      params = params.append('filters', JSON.stringify(request.filters));
      params = params.append('sorters', JSON.stringify(request.sorters));
      params = params.append('skipCount', JSON.stringify(request.skipCount));
      params = params.append('maxResultCount', JSON.stringify(request.maxResultCount));
    }

    return this.http.get(apiUrl, {
      params,
    }) as Observable<ListResponseDto<TDto>>;
  }
  abpGet<TDto>(
    type: any,
    request: ListRequestDto,
    filters: string,
    sorters: string,
    ignoreParameters = false
  ): Observable<ListResponseDto<TDto>> {
    const apiUrl = this.getApiUrl(type);

    if (!ignoreParameters) {
      let params = new HttpParams();

      params = params.append('filter', filters);
      params = params.append('sorting', sorters);
      params = params.append('skipCount', JSON.stringify(request.skipCount));
      params = params.append('maxResultCount', JSON.stringify(request.maxResultCount));

      return this.http.get(apiUrl, {
        params,
      }) as Observable<ListResponseDto<TDto>>;
    }

    return this.http.get(apiUrl) as Observable<ListResponseDto<TDto>>;
  }

  getById<TDto>(
    type: any,
    id: number,
    suffix: string = '',
    options?: HttpRequestOptions
  ): Observable<TDto> {
    const apiUrl = this.getApiUrl(type, id, suffix);

    return this.http.get(apiUrl, options) as Observable<TDto>;
  }

  abpGetById<TDto>(type: any, id: string, suffix: string = ''): Observable<TDto> {
    const apiUrl = this.getApiUrl(type, id, suffix);

    return this.http.get(apiUrl) as Observable<TDto>;
  }

  save<TDto>(
    type: any,
    obj: { id: number | string },
    suffix: string | null = null
  ): Observable<TDto> {
    return (typeof obj.id === 'number' && obj.id > 0) ||
      (typeof obj.id === 'string' && obj.id !== '')
      ? this.update<TDto>(type, obj, suffix)
      : this.insert<TDto>(type, obj, suffix);
  }

  saveWithExternalId<TDto>(
    type: any,
    obj: DtoHasApi,
    id: number | string,
    suffix: string | null = null
  ): Observable<TDto> {
    return (typeof id === 'number' && id > 0) || (typeof id === 'string' && id !== '')
      ? this.updateWithExternalId<TDto>(type, obj, id, suffix)
      : this.insertWithExternalId<TDto>(type, obj, id, suffix);
  }

  insert<TDto>(
    type: any,
    obj: { id: number | string },
    suffix: string | null = null
  ): Observable<TDto> {
    const apiUrl = this.getApiUrl(type, suffix);

    return this.http.post(apiUrl, obj) as Observable<TDto>;
  }

  insertWithoutId<TDto>(type: any, obj: TDto, suffix: string | null = null): Observable<TDto> {
    const apiUrl = this.getApiUrl(type, suffix);

    return this.http.post(apiUrl, obj) as Observable<TDto>;
  }

  insertWithExternalId<TDto>(
    type: any,
    obj: DtoHasApi,
    id: number | string,
    suffix: string | null = null
  ): Observable<TDto> {
    const apiUrl = this.getApiUrl(type, suffix);

    return this.http.post(apiUrl, obj) as Observable<TDto>;
  }

  update<TDto>(
    type: any,
    obj: { id: number | string },
    suffix: string | null = null
  ): Observable<TDto> {
    const apiUrl = this.getApiUrl(type, obj.id, suffix);

    return this.http.put(apiUrl, obj) as Observable<TDto>;
  }

  updateWithExternalId<TDto>(
    type: any,
    obj: DtoHasApi,
    id: number | string,
    suffix: string | null = null
  ): Observable<TDto> {
    const apiUrl = this.getApiUrl(type, id, suffix);

    return this.http.put(apiUrl, obj) as Observable<TDto>;
  }

  deleteObject<TDto>(type: any, obj: { id: number | string }): Observable<any> {
    return this.delete(type, obj.id);
  }

  delete<TDto>(type: any, id: number | string): Observable<any> {
    const apiUrl = this.getApiUrl(type, id);

    return this.http.delete(apiUrl);
  }

  batchSaveWithExternalId<TDto>(
    type: any,
    id: number,
    data: DtoHasApi[],
    suffix: string | null = null
  ): Observable<ListResponseDto<TDto>> {
    const apiUrl = this.getApiUrl(type, id, suffix);

    return this.http.post(apiUrl, data) as Observable<ListResponseDto<TDto>>;
  }

  private getApiUrl(type = DtoHasApi, ...suffixes: (string | number | null)[]): string {
    const apiBase = environment.apis.default.url;
    let apiUrl = apiBase + '/' + type.apiUrl;

    if (suffixes != null && suffixes.length > 0) {
      suffixes.forEach(suffix => {
        apiUrl = suffix !== null && suffix !== undefined ? apiUrl + '/' + suffix : apiUrl;
      });
    }

    return apiUrl;
  }
}
