import { LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { NotificationType } from 'src/core/decorators/notification/notification-type.decorator';
import { NotificationDto } from 'src/core/models/notification/notification.dto';

@Component({
  selector: 'ca-category-deacticated-notification',
  templateUrl: './category-deacticated-notification.component.html',
  styleUrls: ['./category-deacticated-notification.component.scss']
})
@NotificationType(
  'CategoryDeactivatedNotification',
  CategoryDeacticatedNotificationComponent
)
export class CategoryDeacticatedNotificationComponent implements OnInit {
  data: NotificationDto;

  get categoryNames(): string {
    return this.data ? JSON.parse(this.data.payload).categoryNames : '';
  }

  constructor() { }

  ngOnInit(): void { }

  static showNotification(
    payload: string,
    toastr: ToasterService,
    localizationService: LocalizationService,
  ) {
    const parsedPayload = JSON.parse(payload);
    const message = localizationService.instant(
      'Category::UnusedCategoriesDeactived',
      parsedPayload.categoryNames,
    );

    toastr.warn(message);
  }
}