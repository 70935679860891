import { GenericLookupType } from 'src/core/decorators/generic-lookup/generic-lookup-type.decorator';
import { GenericLookup } from 'src/core/decorators/generic-lookup/generic-lookup.decorator';

@GenericLookupType({
  code: 'Conversation.MixedLanguageType',
})
export class MixedLanguageType {
  @GenericLookup({
    code: 'Conversation.MixedLanguageType.SixLanguagesMulti',
  })
  static sixLanguagesMulti: number;

  @GenericLookup({
    code: 'Conversation.MixedLanguageType.FourLanguagesMulti',
  })
  static fourLanguagesMulti: number;

  @GenericLookup({
    code: 'Conversation.MixedLanguageType.DutchFrench',
  })
  static dutchFrench: number;

  @GenericLookup({
    code: 'Conversation.MixedLanguageType.EnglishArabic',
  })
  static englishArabic: number;
}
