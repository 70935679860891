<div>
  <h2>{{ 'Settings::GenerativeQA' | abpLocalization }}</h2>
</div>
<form *ngIf="generativeQAFeatureEnabled" [formGroup]="form" (ngSubmit)="onSubmitForm($event)">
  <div class="form-group pt-2" formGroupName="endpoint">
    <label class="indicates-required-field" for="endpoint">
      {{ 'Settings::GenerativeQA:Endpoint' | abpLocalization }}
    </label>
    <input type="text" class="form-control form-control-sm" id="endpoint" formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="identityBaseEndpoint">
    <label for="identityBaseEndpoint">
      {{ 'Settings::GenerativeQA:IdentityBaseEndpoint' | abpLocalization }}
    </label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="identityBaseEndpoint"
      formControlName="value" />
  </div>
  <div class="form-group pt-2" formGroupName="promptMessage">
    <label for="promptMessage">
      {{ 'Settings::GenerativeQA:PromptMessage' | abpLocalization }}
    </label>
    <input
      type="text"
      class="form-control form-control-sm"
      id="promptMessage"
      formControlName="value" />
  </div>
  <div class="form-check pt-2" formGroupName="agentTranscriptsEnabled">
    <input
      class="form-check-input"
      type="checkbox"
      id="agentTranscriptsEnabled"
      formControlName="value" />
    <label class="ps-1" for="agentTranscriptsEnabled">
      {{ 'Settings::GenerativeQA:AgentTranscriptsEnabled' | abpLocalization }}
    </label>
  </div>
  <div class="form-check pt-2" formGroupName="chatHistoryEnabled">
    <input
      class="form-check-input"
      type="checkbox"
      id="chatHistoryEnabled"
      formControlName="value" />
    <label class="ps-1" for="chatHistoryEnabled">
      {{ 'Settings::GenerativeQA:ChatHistoryEnabled' | abpLocalization }}
    </label>
  </div>
  <button type="button" type="submit" class="btn btn-primary mt-3 mb-3" [disabled]="!form.valid">
    <i class="me-1 fas fa-save"></i>
    {{ 'AbpUi::Save' | abpLocalization }}
  </button>
</form>
