import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FeatureConstants } from 'src/core/constants/feature-constant';
import { ConfigurationSettingsDto } from 'src/core/models/configuration-setting/configuration-settings.dto';
import { ConfigurationSettingsService } from 'src/core/services/configuration-settings/configuration-settings.service';
import { FeatureService } from 'src/core/services/feature/feature.service';
import { StringValidator } from 'src/core/validators/shared/string.validator';

@Component({
  selector: 'ca-generative-qa-settings',
  templateUrl: './generative-qa-settings.component.html',
  styleUrls: ['./generative-qa-settings.component.scss'],
})
export class GenerativeQASettingsComponent implements OnInit {
  form: FormGroup;
  generativeQAFeatureEnabled = false;

  private readonly prefix = 'GenerativeQA';
  private readonly endpoint = this.prefix + '.Endpoint';
  private readonly identityBaseEndpoint = this.prefix + '.IdentityBaseEndpoint';
  private readonly promptMessage = this.prefix + '.PromptMessage';
  private readonly chatHistoryEnabled = this.prefix + '.ChatHistoryEnabled';
  private readonly agentTranscriptsEnabled = this.prefix + '.AgentTranscriptsEnabled';

  constructor(
    private fb: FormBuilder,
    private config: ConfigStateService,
    private settingService: ConfigurationSettingsService,
    private toastr: ToasterService,
    private localizationService: LocalizationService,
    private featureService: FeatureService
  ) {
    this.generativeQAFeatureEnabled = this.featureService.isEnabled(FeatureConstants.GenerativeQA);

    this.form = this.fb.group({
      endpoint: this.fb.group({
        name: this.endpoint,
        value: [
          this.config.getSetting(this.endpoint),
          {
            validators: [Validators.required, StringValidator.whitespace],
          },
        ],
      }),
      identityBaseEndpoint: this.fb.group({
        name: this.identityBaseEndpoint,
        value: [
          this.config.getSetting(this.identityBaseEndpoint),
          {
            validators: [StringValidator.whitespace],
          },
        ],
      }),
      chatHistoryEnabled: this.fb.group({
        name: this.chatHistoryEnabled,
        value: [JSON.parse(this.config.getSetting(this.chatHistoryEnabled).toLowerCase())],
      }),
      promptMessage: this.fb.group({
        name: this.promptMessage,
        value: [
          this.config.getSetting(this.promptMessage),
          {
            validators: [StringValidator.whitespace],
          },
        ],
      }),
      agentTranscriptsEnabled: this.fb.group({
        name: this.agentTranscriptsEnabled,
        value: [JSON.parse(this.config.getSetting(this.agentTranscriptsEnabled).toLowerCase())],
      }),
    });
  }

  ngOnInit(): void {}

  onSubmitForm(eventArgs): void {
    if (!this.form.valid) {
      return;
    }

    const settings: ConfigurationSettingsDto[] = [];

    settings.push(
      {
        settingName: this.form.get('endpoint').get('name').value,
        settingValue: this.form.get('endpoint').get('value').value,
      },
      {
        settingName: this.form.get('identityBaseEndpoint').get('name').value,
        settingValue: this.form.get('identityBaseEndpoint').get('value').value,
      },
      {
        settingName: this.form.get('promptMessage').get('name').value,
        settingValue: this.form.get('promptMessage').get('value').value,
      },
      {
        settingName: this.form.get('chatHistoryEnabled').get('name').value,
        settingValue: this.form.get('chatHistoryEnabled').get('value').value.toString(),
      },
      {
        settingName: this.form.get('agentTranscriptsEnabled').get('name').value,
        settingValue: this.form.get('agentTranscriptsEnabled').get('value').value.toString(),
      }
    );

    this.settingService.saveSetting(settings).subscribe(res => {
      this.toastr.success(
        this.localizationService.instant('AbpSettingManagement::SuccessfullySaved')
      );
    });
  }
}
