import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrderHelper } from 'src/core/helpers/order.helper';
import { ConversationAgentSentiment } from 'src/core/models/generic-lookup-type/conversation/agent-sentiment.glt';
import { GenericLookupDto } from 'src/core/models/generic-lookup/generic-lookup.dto';
import { IntegerQueryItemDto } from 'src/core/models/query/integer-query-item.dto';
import { QueryEditorBase } from 'src/core/models/query/query-editor-base';
import { GenericLookupTypeService } from 'src/core/services/generic-lookup-type/generic-lookup-type.service';
import { GenericLookupTypeState } from 'src/core/states/generic-lookup-type/generic-lookup-type.state';

@Component({
  selector: 'ca-query-tree-node-agent-sentiment',
  templateUrl: './query-tree-node-agent-sentiment.component.html',
  styleUrls: ['./query-tree-node-agent-sentiment.component.scss'],
})
export class QueryTreeNodeAgentSentimentComponent extends QueryEditorBase implements OnInit {
  agentSentimentStatuses$: Observable<GenericLookupDto[]>;
  currentStatus: string;

  payload: IntegerQueryItemDto;

  constructor(private store: Store, private genericLookupService: GenericLookupTypeService, private orderHelper: OrderHelper) {
    super();

    let sentimentOrder = [
      'Conversation.AgentSentiment.VeryNegative',
      'Conversation.AgentSentiment.Negative',
      'Conversation.AgentSentiment.Neutral',
      'Conversation.AgentSentiment.Positive',
      'Conversation.AgentSentiment.VeryPositive'
    ];

    this.agentSentimentStatuses$ = this.store
      .select(GenericLookupTypeState.getGenericLookups)
      .pipe(map(filterFn => filterFn(ConversationAgentSentiment)))
      .pipe(map(list => this.orderHelper.getOrderedGenericLookupList(list.filter(item => item.code !== 'Conversation.AgentSentiment.Unknown'), sentimentOrder)));
  }

  valueChanged() {
    this.node.isDirty = true;
    this.payload.genericLookupCode = this.genericLookupService.findGenericLookupWithId(
      this.payload.value
    )?.code;
  }

  ngOnInit(): void {
    this.payload = this.node.categoryItem.payload as IntegerQueryItemDto;
    this.agentSentimentStatuses$.subscribe((statusList: GenericLookupDto[]) => {
      this.currentStatus = statusList.find(item => item.id === this.payload.value).code;
      this.payload.genericLookupCode = this.currentStatus;
    });

    this.node.validationStatus = true;
  }

  getOptionIcon(code: string) {
    let icon = '';
    if (code?.includes('VeryNegative')) {
      icon = 'fa-regular fa-face-disappointed';
    } else if (code?.includes('Negative')) {
      icon = 'far fa-frown';
    } else if (code?.includes('Neutral')) {
      icon = 'far fa-meh';
    } else if (code?.includes('Positive')) {
      icon = 'far fa-smile';
    } else if (code?.includes('VeryPositive')) {
      icon = 'far fa-smile-beam';
    }
    return icon;
  }
}
